/*
  Theme Name: Finlon - Loan & Credit Repair React Template
  Theme URL: https://mediacity.co.in/finlon/
  Author: Mediacity
  Author URI: https://themeforest.net/user/media-city
  Creation Date: 08 October 2022
  Description: A default stylesheet for CreTech - Finlon - Loan & Credit Repair React Template.
  Version: 1.1
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# video
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
# Footer
# Contact Infos
# Blog
# Team
# Faq
# Services
# Career
# Call to action
# About
# fact
# Testimonial
# Video
# Loan Calculator
# Slider
# Case
# Compare
# Home loan Amortization calculator
# Home loan eligibility calculator
# Apply-now

--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Utility
--------------------------------------------------------------*/
.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-120 {
	margin-top: 120px;
}

.mt--10 {
	margin-top: -10px;
}

.mt--60 {
	margin-top: -60px;
}

.mt--120 {
	margin-top: -120px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-120 {
	margin-bottom: 120px;
}

.mb--60 {
	margin-bottom: -60px;
}

.mb--40 {
	margin-bottom: -40px;
}

.mb--120 {
	margin-bottom: -120px;
}

.mb--190 {
	margin-bottom: -190px;
}

.mb-190 {
	margin-bottom: 190px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-100 {
	padding-top: 100px;
}

.pt-110 {
	padding-top: 110px;
}

.pt-115 {
	padding-top: 115px;
}

.pt-120 {
	padding-top: 120px;
}

.pt-140 {
	padding-top: 140px;
}

.pt-142 {
	padding-top: 142px;
}

.pt-240 {
	padding-top: 240px;
}

.pt-310 {
	padding-top: 310px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pb-110 {
	padding-bottom: 110px;
}

.pb-115 {
	padding-bottom: 115px;
}

.pb-120 {
	padding-bottom: 120px;
}

.pb-140 {
	padding-bottom: 140px;
}

.pb-180 {
	padding-bottom: 180px;
}

.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-30 {
	padding-left: 30px;
}

.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-30 {
	padding-right: 30px;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.video-card {
	background-color: var(--thm-black);
	position: relative;
}

.video-card__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	filter: grayscale(100%);
	opacity: 0.2;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.video-card .container {
	position: relative;
}

.video-card .container p {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--thm-base);
	font-size: 18px;
	font-weight: 500;
	margin: 0;
	line-height: 1;
	margin-bottom: 15px;
}

.video-card .container p img {
	margin-right: 10px;
}

.video-card .container h3 {
	margin: 0;
	color: #fff;
	letter-spacing: 0em;
	font-size: 60px;
	font-weight: bold;
	margin-bottom: 45px;
}

@media (max-width: 575px) {
	.video-card .container h3 {
		font-size: 40px;
	}

	.video-card .container h3 br {
		display: none;
	}
}

@media (max-width: 375px) {
	.video-card .container h3 {
		font-size: 30px;
	}
}

.video-card__btn-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.video-card__btn {
	width: 63px;
	height: 63px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 12px;
	margin-left: 30px;
	background-color: var(--thm-primary);
	color: #fff;
}

.video-card__btn:hover {
	color: var(--thm-primary);
	background-color: #fff;
}

.video-card-two {
	padding-top: 100px;
	position: relative;
	z-index: 11;
}

.video-card-two .inner-container {
	background-position: top right;
	background-repeat: no-repeat;
	background-color: #242323;
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 65px;
	padding-bottom: 20px;
	position: relative;
	z-index: 11;
}

@media (max-width: 991px) {
	.video-card-two .inner-container {
		padding-top: 40px;
		padding-bottom: 30px;
		text-align: center;
		padding-right: 20px;
		border-radius: 0px !important;
	}
}

.video-card-two h3 {
	margin: 0;
	font-size: 36px;
	line-height: 50px;
	letter-spacing: -0.05em;
	font-weight: bold;
	color: #fff;
	padding-left: 22px;
	position: relative;
	left: 30px;
}

@media (max-width: 1199px) {
	.video-card-two h3 {
		left: 0;
		font-size: 30px;
		line-height: 1.5;
	}
}

@media (max-width: 991px) {
	.video-card-two h3 {
		margin-top: 20px;
		margin-bottom: 20px;
		padding-left: 0;
	}
}

.video-card-two p {
	margin: 0;
	padding-left: 70px;
	font-size: 18px;
	line-height: 34px;
	font-weight: 500;
	color: #9e9e9e;
}

@media (max-width: 1199px) {
	.video-card-two p {
		padding-left: 0;
	}
}

.video-card-two__box {
	display: inline-block;
	position: relative;
}

@media (max-width: 991px) {
	.video-card-two__box {
		width: 251px;
		height: 251px;
		margin-left: auto;
		display: block;
		margin-right: auto;
	}
}

.video-card-two__box>img {
	border-radius: 50%;
}

@media (max-width: 1199px) {
	.video-card-two__box>img {
		max-width: 100%;
	}
}

.video-card-two__box-btn {
	width: 56px;
	height: 56px;
	background-color: var(--thm-base);
	color: #fff;
	display: flex;
	align-items: center;
	font-size: 12px;
	justify-content: center;
	transition: 500ms ease;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%) translateX(50%);
}

.video-card-two__box-btn:hover {
	color: #fff;
	background-color: var(--thm-primary);
}

@media (max-width: 575px) {
	.video-card-two__box-btn {
		top: 50%;
		right: auto;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

.team-card__image {
	position: relative;
}

.team-card__image img {
	width: 100%;
}

.team-card__social {
	position: absolute;
	bottom: 0;
	right: 0;
	display: inline-flex;
	align-items: center;
	transform: scale(1, 0);
	transform-origin: top center;
	opacity: 0;
	transition: transform 500ms ease, opacity 500ms ease;
}

.team-card__social a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: var(--thm-base);
	transition: all 500ms ease;
	font-size: 14px;
	color: #fff;
	transition: all 500ms ease;
}

.team-card__social a:hover {
	background-color: #c1180b;
}

.team-card:hover .team-card__social {
	transform: scale(1, 1);
	opacity: 1;
	transform-origin: bottom center;
}

.team-card__content {
	position: relative;
	padding-right: 50px;
}

.team-card__content__inner {
	padding-top: 26px;
	padding-bottom: 27px;
	background-color: var(--thm-gray);
	padding-left: 34px;
	border-left: 4px solid var(--thm-black);
	transition: all 500ms ease;
}

.team-card:hover .team-card__content__inner {
	background-color: #fff;
	border-color: var(--thm-base);
	box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.team-card__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}

.team-card__title:hover {
	color: var(--thm-black);
}

.team-card__title a {
	color: inherit;
}

.team-card__title a:hover {
	color: inherit;
}

.team-card__designation {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: .2em;
	color: var(--thm-base);
	margin: 0;
	line-height: 1;
}

.blog-card {
	position: relative;
	background-color: #fff;
}

.blog-card__image {
	position: relative;
	overflow: hidden;
}

.blog-card__image>img {
	width: 100%;
	transform: scale(1);
	transition: transform 500ms ease;
}

.blog-card__image>a {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: rgba(var(--thm-black-rgb), 0.4);
	position: absolute;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transform: translateY(-20%);
	transition: opacity 500ms ease, transform 500ms ease;
}

.blog-card__image>a::before,
.blog-card__image>a::after {
	content: "";
	width: 32px;
	height: 2px;
	background-color: #fff;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.blog-card__image>a::after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.blog-card:hover .blog-card__image>a {
	opacity: 1;
	transform: translateY(0);
}

.blog-card:hover .blog-card__image>img {
	transform: scale(1.05);
}

.blog-card__content {
	background-color: #fff;
	position: relative;
	text-align: center;
	padding-top: 30px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
	border: 1px solid var(--thm-gray);
	border-top: 0;
	transition: all 500ms ease-out;
}

.blog-card:hover .blog-card__content {
	box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.blog-card__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 24px;
	line-height: 1.5em;
	font-weight: 700;
	margin-top: 4px;
	margin-bottom: 11px;
}

.blog-card__title a {
	color: inherit;
	transition: all 500ms ease;
}

.blog-card__title a:hover {
	color: var(--thm-base);
}

.blog-card__text {
	margin: 0;
	margin-bottom: 31px;
}

.blog-card__date {
	width: 70px;
	height: 70px;
	background-color: var(--thm-base);
	font-size: 12px;
	letter-spacing: .2em;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 1;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 10;
	color: #fff;
}

.blog-card__date span {
	display: block;
	letter-spacing: 0;
	font-size: 20px;
	margin-bottom: 1px;
}

.blog-card__meta {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -6px;
}

.blog-card__meta a {
	display: flex;
	align-items: center;
	color: var(--thm-color);
	font-size: 14px;
	transition: all 500ms ease;
	font-weight: 400;
}

.blog-card__meta a:not(:last-child)::after {
	content: '/';
	margin-left: 9px;
	margin-right: 9px;
}

.blog-card__meta a i {
	color: var(--thm-base);
	margin-right: 4px;
}

.blog-card__meta a:hover {
	color: var(--thm-base);
}

.blog-card__meta a:hover i {
	color: var(--thm-black);
}

.blog-card__link {
	display: flex;
	width: 100%;
	color: var(--thm-black);
	background-color: var(--thm-gray);
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	transition: all 0.5s ease;
	padding: 13.75px 0;
	letter-spacing: .2em;
}

.blog-card__link:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.blog-card:hover .blog-card__link {
	background-color: var(--thm-black);
	color: #fff;
}

.blog-card-two {
	position: relative;
	background-color: var(--thm-black);
	min-height: 405px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding: 40px;
}

.blog-card-two__image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
}

.blog-card-two__image::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(0deg, var(--thm-black) 0%, rgba(var(--thm-black-rgb), 0) 100%);
}

.blog-card-two__content {
	position: relative;
	z-index: 10;
}

.blog-card-two__title {
	margin: 0;
	font-size: 26px;
	font-weight: bold;
	line-height: 1.38462em;
	color: #fff;
	margin-bottom: -3px;
	margin-top: 17px;
}

.blog-card-two__title a {
	color: inherit;
	transition: all 0.5s ease;
}

.blog-card-two__title a:hover {
	color: var(--thm-base);
}

.blog-card-two .blog-card__meta {
	justify-content: flex-start;
}

.blog-card-two .blog-card__meta a {
	color: #fff;
}

.blog-card-two .blog-card__meta a i {
	color: var(--thm-base);
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
	scroll-behavior: auto;
	--thm-font: "Inter", sans-serif;
	--heading-font: "Inter", sans-serif;
	--special-font: "reeyregular", sans-serif;
	--thm-color: #68686f;
	--thm-color-rgb: 104, 104, 111;
	--thm-base: #7D004D;
	--thm-base-rgb: 230, 58, 39;
	--thm-primary: #7D004D;
	--thm-primary-rgb: 247, 195, 95;
	--thm-secondary: #7D004D;
	--thm-secondary-rgb: 237, 75, 75;
	--thm-special: #5366c2;
	--thm-special-rgb: 83, 102, 194;
	--thm-black: #1c1c27;
	--thm-black-rgb: 28, 28, 39;
	--thm-black2: #16161f;
	--thm-black2-rgb: 22, 22, 31;
	--thm-gray: #f2ede9;
	--thm-gray-rgb: 242, 237, 233;
	--thm-border-color: #e7e1dc;
	--thm-border-color-rgb: 231, 225, 220;
	--thm-text-dark: #a2a2b1;
	--thm-text-dark-rgb: 162, 162, 177;
}

body {
	font-family: var(--thm-font);
	color: var(--thm-color);
	font-size: 16px;
	line-height: 1.875;
}

body.locked {
	overflow: hidden;
}

a {
	color: var(--thm-base);
}

a,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
}

::placeholder {
	color: inherit;
	opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--heading-font);
	color: var(--thm-black);
}

@media (max-width: 575px) {

	h1 br,
	h2 br,
	h3 br,
	h4 br,
	h5 br,
	h6 br {
		display: none;
	}
}

@media (max-width: 575px) {
	p br {
		display: none;
	}
}

::placeholder {
	color: inherit;
	opacity: 1;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
	font-family: inherit;
}

.background-base {
	background-color: var(--thm-base);
}

.background-primary {
	background-color: var(--thm-primary);
}

.background-secondary {
	background-color: var(--thm-secondary);
}

.background-special {
	background-color: var(--thm-special);
}

.background-black {
	background-color: var(--thm-black);
}

.background-black-2 {
	background-color: var(--thm-black2);
}

.thm-text-dark {
	color: var(--thm-text-dark);
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
	overflow: visible;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.row {
	--bs-gutter-x: 30px;
	--bs-gutter-y: 0px;
}

.row-gutter-x-0 {
	--bs-gutter-x: 0px;
}

.row-gutter-x-20 {
	--bs-gutter-x: 20px;
}

.row-gutter-y-10 {
	--bs-gutter-y: 10px;
}

.row-gutter-y-20 {
	--bs-gutter-y: 20px;
}

.row-gutter-y-30 {
	--bs-gutter-y: 30px;
}

.row-gutter-y-50 {
	--bs-gutter-y: 50px;
}

.row-gutter-y-60 {
	--bs-gutter-y: 60px;
}

.row-gutter-y-80 {
	--bs-gutter-y: 80px;
}

.thm-btn {
	display: inline-block;
	vertical-align: middle;
	-webkit-appearance: none;
	border: none;
	outline: none !important;
	font-size: 13px !important;
	font-family: var(--thm-font);
	padding: 18px 20px;
	transition: 500ms;
	line-height: 1;
	background-color: var(--thm-base);
	color: #fff;
	/* width: 300px; */
	text-align: center;
	min-width: 180px;
	border-radius: 3px;
}

.thm-btn3 {
	display: inline-block;
	vertical-align: middle;
	-webkit-appearance: none;
	border: none;
	outline: none !important;
	font-size: 18px !important;
	font-family: var(--thm-font);
	padding: 15px 20px;
	transition: 500ms;
	line-height: 1;
	background-color: var(--thm-base);
	color: #fff;
	/* width: 300px; */
	text-align: center;
	min-width: 180px;
	border-radius: 3px;
	font-weight: 600;
}

@media (min-width: 600px) {
	.thm-btn {
		width: 100%;
		/* padding: 23px 46px; */
	}

}

@media (max-width: 600px) {
	.thm-btn {
		width: 100%;
		/* padding: 23px 46px; */
	}

}


.thm-btn2 {
	display: inline-block;
	vertical-align: middle;
	-webkit-appearance: none;
	border: 2px solid var(--thm-base);
	outline: none !important;
	font-size: 15px !important;
	font-family: var(--thm-font);
	padding: 23px 46px;
	transition: 500ms;
	line-height: 1;
	background-color: #fff;
	color: #000;
}

.thm-btn:hover {
	color: var(--thm-base);
	background-color: #fff;
}

.thm-btn--dark-hover:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.thm-btn3:hover {
	color: #fff;
	/* color: var(--thm-base); */
	/* background-color: #fff; */
}

.thm-btn3--dark-hover:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.thm-btn2:hover {
	background-color: #000;
	color: #fff;
}

.bootstrap-select .dropdown-menu {
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 0;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
	background-color: var(--thm-base);
}

.tns-outer .tns-controls {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
}

.tns-outer .tns-controls button {
	width: 45px;
	height: 45px;
	border: 2px solid #f4f4f4;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--thm-color);
	border-radius: 50%;
	margin-left: 5px;
	margin-right: 5px;
}

.block-separator {
	margin: 0;
	background-color: var(--thm-border-color);
	opacity: 1;
}

.block-title {
	margin-bottom: 50px;
}

.block-title__tagline {
	text-transform: uppercase;
	color: var(--thm-base);
	font-size: 14px;
	font-weight: 700;
	margin: 0;
	line-height: 1;
	letter-spacing: .2em;
	position: relative;
	top: -1px;
}

.block-title__tagline::after,
.block-title__tagline::before {
	position: relative;
	top: -1px;
	content: '||';
	font-size: 10px;
}

.block-title__tagline::before {
	margin-right: 6px;
}

.block-title__tagline::after {
	margin-left: 6px;
}

.block-title__title {
	margin: 0;
	font-weight: bold;
	font-size: 35px;
	line-height: 1.2em;
	color: var(--thm-black);
	position: relative;
	bottom: -10px;
	margin-top: -3px;
}

@media (min-width: 768px) {
	.block-title__title {
		font-size: 40px;
	}
}

@media (min-width: 992px) {
	.block-title__title {
		font-size: 45px;
	}
}

.ul-list-one {
	margin-bottom: 0;
}

.ul-list-one li {
	position: relative;
	padding-left: 45px;
	font-size: 16px;
	font-weight: 500;
	color: var(--thm-black);
}

@media (min-width: 481px) {
	.ul-list-one li {
		font-size: 20px;
	}
}

.ul-list-one li::before {
	content: "\e907";
	color: var(--thm-secondary);
	font-size: 26px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	font-family: "azino-icon";
}

.preloader {
	position: fixed;
	background-color: var(--thm-black);
	background-position: center center;
	background-repeat: no-repeat;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9991;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	text-align: center;
}

.preloader__image {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: flipInY;
	animation-name: flipInY;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	display: block;
	width: 100%;
	height: 100%;
	background-image: url(../images/loader.png);
	background-size: 50px;
	background-repeat: no-repeat;
	background-position: center center;
}

/* scroll to top */
.scroll-to-top {
	display: inline-block;
	width: 45px;
	height: 45px;
	background: var(--thm-base);
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 99;
	text-align: center;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: none;
	border-radius: 50%;
	transition: all 0.4s ease;
}

.scroll-to-top i {
	color: #ffffff;
	font-size: 18px;
	line-height: 45px;
}

.scroll-to-top:hover {
	background-color: var(--thm-black);
}

.scroll-to-top:hover i {
	color: #fff;
}

/* post paginations */
.post-pagination {
	margin-bottom: 0;
	margin-top: 0px;
}

@media (min-width: 992px) {
	.post-pagination {
		margin-top: 0px;
	}
}

.post-pagination a {
	display: flex;
	width: 45px;
	height: 45px;
	background-color: #eff2f6;
	align-items: center;
	justify-content: center;
	color: var(--thm-color);
	font-size: 16px;
	font-weight: 500;
	border-radius: 50%;
	transition: 500ms ease;
}

@media (min-width: 992px) {
	.post-pagination a {
		width: 60px;
		height: 60px;
		font-size: 18px;
	}
}

.post-pagination a:hover {
	background-color: var(--thm-base);
	color: #fff;
}

.post-pagination li:first-child a {
	background-color: var(--thm-base);
	color: #fff;
}

.post-pagination li:last-child a {
	background-color: var(--thm-black);
	color: #fff;
}

.post-pagination li+li {
	margin-left: 10px;
}

.owl-dot-style-one .owl-dots {
	display: flex;
	justify-content: center;
}

.owl-dot-style-one .owl-dots .owl-dot+.owl-dot {
	margin-left: 10px;
}

.owl-dot-style-one .owl-dots .owl-dot span {
	margin: 0;
	background-color: var(--thm-black);
	opacity: 0.1;
	transform: scale(1);
	transition: 500ms ease;
}

.owl-dot-style-one .owl-dots .owl-dot.active span {
	opacity: 1;
	background-color: var(--thm-base);
	transform: scale(1.6);
}

.owl-dot-style-one .owl-nav.disabled+.owl-dots {
	margin-top: 60px;
}

.image-box img {
	max-width: 100%;
	margin-bottom: 30px;
}

.image-box__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 24px;
	font-weight: bold;
	line-height: 1.2em;
	margin-top: -4px;
	margin-bottom: 17px;
}

.image-box__title a {
	color: inherit;
	transition: all 500ms ease;
}

.image-box__title a:hover {
	color: var(--thm-base);
}

.image-box__text {
	margin: 0;
	margin-bottom: -7px;
}

.image-box-two__image {
	position: relative;
}

.image-box-two__image::before {
	content: '';
	width: 270px;
	height: 390px;
	position: absolute;
	top: 40px;
	right: 0px;
	background-image: linear-gradient(0deg, #ffffff 1%, var(--thm-gray) 100%);
}

.image-box-two__image img {
	max-width: 100%;
	position: relative;
}

.image-box-two__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 30px;
	line-height: 1.2em;
	font-weight: bold;
	max-width: 398px;
	position: relative;
	margin-top: -7px;
}

.image-box-two__title a {
	color: inherit;
	transition: all 500ms ease;
}

.image-box-two__title a:hover {
	color: var(--thm-base);
}

.image-box-two__text {
	margin-bottom: -6px;
	position: relative;
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.form-control,
.form-one input[type="text"],
.form-one input[type="email"],
.form-one input[type="number"],
.form-one select,
.form-one textarea {
	display: block;
	width: 100%;
	background-color: var(--thm-gray);
	color: var(--thm-black);
	font-size: 14px;
	font-weight: 400;
	border: none;
	outline: none;
	padding: 10px 30px;
}

.form-control:focus {
	box-shadow: none;
	background-color: var(--thm-gray);
	border: 0;
}

.form-one input[type="text"],
.form-one input[type="email"],
.form-one input[type="number"],
.form-one select,
.form-one textarea {
	height: 60px;
	padding: 0 30px;
}

.finloan-apply-one .form-one input[type="text"],
.finloan-apply-one .form-one input[type="email"],
.finloan-apply-one .form-one input[type="number"],
.finloan-apply-one .form-one select,
.finloan-apply-one .form-one textarea {
	background-color: #fff;
}

.form-one textarea {
	height: 170px;
	padding-top: 20px;
}

.form-one .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

/*--------------------------------------------------------------
# Navigations
--------------------------------------------------------------*/
.topbar {
	background-color: var(--thm-black);
	position: relative;
	top: -2px;
}

@media (max-width: 1199px) {
	.topbar {
		display: none;
	}
}

.topbar .container-fluid {
	display: flex;
	align-items: center;
}

@media (min-width: 1200px) {
	.topbar .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
}

.topbar__info {
	padding-left: 355px;
	display: flex;
	align-items: center;
	margin-left: -15px;
	margin-right: -15px;
}

.topbar__info a {
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	align-items: center;
	color: inherit;

	color: #fff;
	font-size: 14px;
	font-weight: 200;
	transition: all 500ms ease;

	/* line-height: 1.7em; */
}

.topbar__info a:hover {
	color: #ffffff;
}

.topbar__info a i {
	color: var(--thm-base);
	font-size: 14px;
	margin-right: 10px;
	/* background-color: var(--thm-base);
	border-radius: 50%;
	padding: 10px; */
}

.topbar__links {
	margin-left: auto;
	display: none;
	align-items: center;
}

@media (min-width: 1281px) {
	.topbar__links {
		display: flex;
	}
}

.topbar__links a {
	display: flex;
	align-items: center;
	color: inherit;
	color: var(--thm-text-dark);
	font-size: 12px;
	font-weight: 400;
	transition: all 500ms ease;
	line-height: 1.7em;
}

.topbar__links a+a {
	margin-left: 20px;
}

.topbar__links a:hover {
	color: #ffffff;
}

.topbar__social {
	display: flex;
	align-items: center;
	padding-right: 48px;
	padding-left: 40px;
	margin-left: auto;
}

@media (min-width: 1281px) {
	.topbar__social {
		margin-left: 0;
	}
}

.topbar__social a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	font-size: 14px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	transition: all 500ms ease;
}

.topbar__social a:hover {
	background-color: var(--thm-black2);
	color: var(--thm-base);
}

.topbar--two {
	background-color: #fff;
}

.topbar--two .container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 27px;
	padding-bottom: 20px;
}

.topbar--two .topbar__info {
	padding-left: 0px;
}

.topbar--two .topbar__info a {
	color: var(--thm-base);
}

.topbar--two .topbar__social {
	padding: 0;
}

.topbar--two .topbar__social a {
	width: auto;
	height: auto;
	background-color: rgba(0, 0, 0, 0) !important;
	/* color: var(--thm-color); */
	color: var(--thm-base);
}

.topbar--two .topbar__social a+a {
	margin-left: 28px;
}

.topbar--two .topbar__social a:hover {
	color: var(--thm-base);
}

.main-menu {
	position: relative;
	z-index: 91;
}

.main-menu .container-fluid {
	display: flex;
	align-items: center;
}

@media (min-width: 1200px) {
	.main-menu .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
}

.main-menu__logo {
	position: absolute;
	top: -40px;
	left: 0;
	width: 265px;
	height: 82px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1199px) {
	.main-menu__logo {
		position: relative;
		top: auto;
		left: auto;
		height: 80px;
		width: auto;

	}
}

.main-menu__logo img {
	position: relative;
	z-index: 11;
	margin-left: 0px;
}

@media (max-width: 600px) {
	.main-menu__logo img {
		margin-left: -38px;
		margin-top: -8px;
	}
}


@media (min-width: 1024px) {
	.main-menu__logo img {
		margin-left: 20px;
	}
}

.main-menu__nav {
	padding-left: 375px;
}

@media (min-width: 1200px) {
	.main-menu__nav {
		padding-left: 250px;
	}
}

@media (min-width: 1599px) {
	.main-menu__nav {
		padding-left: 350px;
	}
}

/* @media (max-width: 1599px) {
	.main-menu__nav {
		padding-left: 300px;
	}
} */

@media (max-width: 1199px) {
	.main-menu__nav {
		display: none;
	}
}

.main-menu__right {
	margin-left: auto;
	display: flex;
	align-items: center;
	padding-right: 40px;
}

@media (max-width: 1199px) {
	.main-menu__right {
		padding-top: 0px;
		padding-right: 0;
	}
}

.main-menu__toggler {
	font-size: 20px;
	color: var(--thm-black);
	margin-right: 10px;
	transition: all 500ms ease;
	display: none;
}

@media (max-width: 1199px) {
	.main-menu__toggler {
		display: flex;
	}
}

.main-menu__toggler:hover {
	color: var(--thm-base);
}

.main-menu__search {
	font-size: 24px;
	color: var(--thm-black);
	border-left: 1px solid var(--thm-border-color);
	padding-left: 30px;
	display: flex;
	align-items: center;
	height: 40px;
	padding-right: 30px;
	transition: all 500ms ease;
}

@media (max-width: 475px) {
	.main-menu__search {
		padding-right: 0;
	}
}

.main-menu__search:hover {
	color: var(--thm-base);
}

.main-menu__btn {
	padding: 19px 10px 19px 18px;
	font-size: 12px;
	margin-right: -25px;
}

.main-menu__btn2 {
	/* padding: 19px 10px 19px 18px; */
	font-size: 20px;
	/* margin-right: -25px; */
}

@media (max-width: 1200px) {
	.main-menu__btn {
		margin-right: 30px;
	}
}

@media (min-width: 1581px) {
	.main-menu__btn {
		margin-right: 30px;
	}
}

@media (max-width: 475px) {
	.main-menu__btn {
		display: none;
	}
}

@media (max-width: 600px) {
	.main-menu__btn2 {
		display: none;
	}
}

.main-menu__btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.main-menu__contact {
	display: none;
	align-items: center;
}

@media (min-width: 1581px) {
	.main-menu__contact {
		display: flex;
	}
}

.main-menu__contact__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: var(--thm-gray);
	color: var(--thm-base);
	font-size: 27px;
	border-radius: 50%;
	margin-right: 13px;
	transition: all 500ms ease;
}

.main-menu__contact__text {
	color: var(--thm-color);
	transition: all 500ms ease;
	font-size: 14px;
	line-height: 1;
}

.main-menu__contact__text strong {
	display: block;
	font-size: 16px;
	font-weight: 600;
	color: var(--thm-black);
	margin-bottom: 10px;
}

.main-menu__contact:hover .main-menu__contact__icon {
	background-color: var(--thm-base);
	color: #fff;
}

.main-menu__contact:hover .main-menu__contact__text {
	color: var(--thm-color);
}

[class*=main-menu__logo__shape-] {
	position: absolute;
	width: 317px;
	height: 120px;
}

@media (max-width: 1199px) {
	[class*=main-menu__logo__shape-] {
		height: 80px;
		display: none;
	}
}

.main-menu__logo__shape-1 {
	fill: var(--thm-base);
	left: 0;
}

.main-menu__logo__shape-2 {
	fill: #ffffff;
	left: -5px;
}

.main-menu,
.stricky-header {
	/* after third level no menu */
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	align-items: center;
	display: none;
}

@media (min-width: 1200px) {

	.main-menu .main-menu__list,
	.main-menu .main-menu__list ul,
	.stricky-header .main-menu__list,
	.stricky-header .main-menu__list ul {
		display: flex;
	}
}

.main-menu .main-menu__list,
.stricky-header .main-menu__list {
	margin-left: auto;
	margin-right: auto;
}

.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
	padding-top: 25px;
	padding-bottom: 25px;
	position: relative;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
	margin-left: 35px;
}

@media (min-width: 1290px) {

	.main-menu .main-menu__list>li+li,
	.stricky-header .main-menu__list>li+li {
		margin-left: 45px;
	}
}

@media (min-width: 1400px) {

	.main-menu .main-menu__list>li+li,
	.stricky-header .main-menu__list>li+li {
		margin-left: 60px;
	}
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
	font-family: var(--thm-font);
	font-size: 14px;
	display: flex;
	align-items: center;
	color: var(--thm-color);
	position: relative;
	transition: all 500ms ease;
}

.main-menu .main-menu__list>li>a::after,
.stricky-header .main-menu__list>li>a::after {
	content: '';
	width: 100%;
	height: 2px;
	background-color: var(--thm-primary);
	position: absolute;
	bottom: 0;
	left: 0;
	transform: scale(0, 1);
	transform-origin: right center;
	transition: transform 500ms ease;
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
	color: var(--thm-primary);
	text-shadow: 0 0 1px currentColor;
}

.main-menu .main-menu__list>li.current>a::after,
.main-menu .main-menu__list>li:hover>a::after,
.stricky-header .main-menu__list>li.current>a::after,
.stricky-header .main-menu__list>li:hover>a::after {
	transform: scale(1, 1);
	transform-origin: left center;
}

.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 220px;
	background-color: #fff;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	opacity: 0;
	visibility: hidden;
	transition: 500ms ease;
	z-index: 99;
	box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

.main-menu .main-menu__list li:hover>ul,
.stricky-header .main-menu__list li:hover>ul {
	opacity: 1;
	visibility: visible;
}

.main-menu .main-menu__list li ul li,
.stricky-header .main-menu__list li ul li {
	flex: 1 1 100%;
	width: 100%;
	position: relative;
}

.main-menu .main-menu__list li ul li+li,
.stricky-header .main-menu__list li ul li+li {
	border-top: 1px solid RGBA(var(--thm-black), 0.1);
}

.main-menu .main-menu__list li ul li a,
.stricky-header .main-menu__list li ul li a {
	font-size: 14px;
	line-height: 30px;
	color: var(--thm-black);
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: 500ms;
}

.main-menu .main-menu__list li ul li:hover>a,
.stricky-header .main-menu__list li ul li:hover>a {
	background-color: var(--thm-base);
	color: #fff;
}

.main-menu .main-menu__list li ul li>ul,
.stricky-header .main-menu__list li ul li>ul {
	top: 0;
	left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
	top: 0;
	left: auto;
	right: 100%;
}

.main-menu .main-menu__list li ul li>ul ul,
.stricky-header .main-menu__list li ul li>ul ul {
	display: none;
}

.stricky-header {
	position: fixed;
	z-index: 991;
	top: 0;
	left: 0;
	background-color: #ffffff;
	width: 100%;
	transform: translateY(-120%);
	transition: transform 500ms ease;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
	.stricky-header {
		display: none;
	}
}

.stricky-header .main-menu__logo {
	top: 0;
	height: 80px;
}

.stricky-header .main-menu__logo__shape-1,
.stricky-header .main-menu__logo__shape-2 {
	height: 80px;
}

.stricky-header.stricky-fixed {
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: flipInX;
	animation-name: flipInX;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.mobile-nav__buttons {
	display: flex;
	margin-left: auto;
	margin-right: 10px;
}

@media (min-width: 1200px) {
	.mobile-nav__buttons {
		display: none;
	}
}

.mobile-nav__buttons a {
	font-size: 20px;
	color: var(--thm-black);
	cursor: pointer;
}

.mobile-nav__buttons a+a {
	margin-left: 10px;
}

.mobile-nav__buttons a:hover {
	color: var(--thm-base);
}

.mobile-nav__toggler {
	font-size: 20px;
	color: var(--thm-black);
	cursor: pointer;
	transition: 500ms;
}

.mobile-nav__toggler:hover {
	color: var(--thm-base);
}

@media (min-width: 1200px) {
	.mobile-nav__toggler {
		display: none;
	}
}

.main-menu--two:not(.stricky-header) {
	/* position: relative;
	margin-bottom: -40px; */
}

.main-menu--two .main-menu__logo {
	position: relative;
	top: auto;
	left: auto;
	/* height: 110px; */
	background-color: var(--thm-base);
	width: 260px;
	/* top: -30px; */
	padding-top: 0px;
}

@media (max-width: 1199px) {
	.main-menu--two .main-menu__logo {
		top: auto;
		width: auto;
		padding-left: 20px;
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.main-menu--two .main-menu__logo::after {
	content: '';
	width: 15px;
	height: 30px;
	top: 0;
	left: 100%;
	background-color: var(--thm-base);
	position: absolute;
	/* clip-path: polygon(100% 100%, 0 0, 0 100%); */
}

.main-menu--two__inner {
	background-color: var(--thm-base);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-right: 20px;
}

.main-menu--two .main-menu__nav {
	padding-left: 0;
	margin-left: auto;
	margin-right: auto;
}

.main-menu--two .main-menu__list>li {
	padding-top: 40px;
	padding-bottom: 40px;
}

.main-menu--two .main-menu__list>li+li {
	margin-left: 40px;
}

.main-menu--two .main-menu__right {
	padding-right: 0;
	margin: 0;
}

@media (max-width: 1199px) {
	.main-menu--two .main-menu__right {
		margin-left: auto;
	}
}

.main-menu--two .main-menu__btn {
	margin-right: 0;
	background-color: #fff;
	color: var(--thm-black);
}

@media (max-width: 520px) {
	.main-menu--two .main-menu__btn {
		display: none;
	}
}

.main-menu--two .main-menu__btn:hover {
	background-color: #fff;
	color: var(--thm-black);
}

.main-menu--two .main-menu__search {
	color: #fff;
	border-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 520px) {
	.main-menu--two .main-menu__search {
		padding-right: 0;
	}
}

.main-menu--two .main-menu__list>li>a {
	/* color: var(--thm-text-dark); */
	color: #fff;

}

.main-menu--two .mobile-nav__toggler {
	color: #fff;
}

.stricky-header.main-menu--two {
	background-color: var(--thm-base);
}

.stricky-header.main-menu--two .main-menu__logo {
	top: auto;
	padding-top: 0;
	height: auto;
	width: auto;
	justify-content: flex-start;
}

.stricky-header.main-menu--two .main-menu__list>li {
	padding-top: 30px;
	padding-bottom: 30px;
}

.stricky-header.main-menu--two .main-menu--two__inner {
	padding-right: 0;
}

.main-menu--three .main-menu__logo {
	width: 265px;
	height: 82px;
	background-color: #fff;
	top: 0;
}

@media (max-width: 600px) {
	.main-menu--three .main-menu__logo {
		width: auto;
		height: auto;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.main-menu--three .main-menu__list>li {
	padding-top: 31px;
	padding-bottom: 31px;
}

@media (max-width: 600px) {
	.main-menu--three .main-menu__btn {
		display: none;
	}
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
	0% {
		transform: translateY(0px) translateX(0) rotate(0);
	}

	30% {
		transform: translateY(10px) translateX(30px) rotate(5deg);
		transform-origin: center center;
	}

	50% {
		transform: translateY(30px) translateX(50px) rotate(15deg);
		transform-origin: right bottom;
	}

	80% {
		transform: translateY(10px) translateX(30px) rotate(5deg);
		transform-origin: left top;
	}

	100% {
		transform: translateY(0px) translateX(0) rotate(0);
		transform-origin: center center;
	}
}

@keyframes shapeMover {

	0%,
	100% {
		transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
	}

	50% {
		transform: perspective(400px) rotate(-20deg) translateZ(10px) translateY(10px) translateX(10px);
	}
}

@keyframes banner3Shake {
	0% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}

	30% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	60% {
		-webkit-transform: rotate3d(1, 0, 0, 0deg);
		transform: rotate3d(1, 0, 0, 0deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	100% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}
}

@keyframes squareMover {

	0%,
	100% {
		-webkit-transform: translate(0, 0) rotate(0);
		transform: translate(0, 0) rotate(0);
	}

	20%,
	60% {
		-webkit-transform: translate(20px, 40px) rotate(180deg);
		transform: translate(20px, 40px) rotqte(180deg);
	}

	30%,
	80% {
		-webkit-transform: translate(40px, 60px) rotate(0deg);
		transform: translate(40px, 60px) rotate(0deg);
	}
}

@keyframes treeMove {

	0%,
	100% {
		-webkit-transform: rotate(0deg) translateX(0);
		transform: rotate(0deg) translateX(0);
	}

	25%,
	75% {
		-webkit-transform: rotate(5deg) translateX(15px);
		transform: rotate(5deg) translateX(15px);
	}

	50% {
		-webkit-transform: rotate(10deg) translateX(30px);
		transform: rotate(10deg) translateX(30px);
	}
}

@keyframes zoom-fade {
	0% {
		transform: scale(0.9);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.9);
	}
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	transform-origin: right center;
	z-index: 999;
	transform: translateX(-100%);
	transform-origin: left center;
	transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
	visibility: hidden;
}

.mobile-nav__wrapper .container {
	padding-left: 0;
	padding-right: 0;
}

.mobile-nav__wrapper.expanded {
	opacity: 1;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
	visibility: visible;
	-webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
	transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000;
	opacity: 0.5;
	cursor: pointer;
}

.mobile-nav__content {
	width: 300px;
	background-color: var(--thm-black);
	z-index: 10;
	position: relative;
	height: 100%;
	overflow-y: auto;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 15px;
	padding-right: 15px;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
		-webkit-transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
		-webkit-transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
		transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
		transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(0);
	transform: translateX(0);
	-webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
		-webkit-transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
		-webkit-transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
		transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
		transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .thm-btn {
	padding: 8px 0;
	width: 100%;
	text-align: center;
}

.mobile-nav__content .logo-box {
	margin-bottom: 40px;
	display: flex;
}

.mobile-nav__close {
	position: absolute;
	top: 20px;
	right: 15px;
	font-size: 18px;
	color: var(--thm-text-dark);
	cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
	display: none;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list ul li a {
	padding-left: 1em;
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li a {
	display: flex;
	justify-content: space-between;
	line-height: 30px;
	color: #ffffff;
	font-size: 14px;
	font-family: var(--thm-font);
	font-weight: 500;
	height: 46px;
	align-items: center;
	transition: 500ms;
}

.mobile-nav__content .main-menu__list li a.expanded {
	color: var(--thm-base);
}

.mobile-nav__content .main-menu__list li a button {
	width: 30px;
	height: 30px;
	background-color: var(--thm-base);
	border: none;
	outline: none;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	transform: rotate(-90deg);
	transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
	transform: rotate(0deg);
	background-color: #fff;
	color: var(--thm-black);
}

.mobile-nav__content .main-menu__list li.cart-btn span {
	position: relative;
	top: auto;
	right: auto;
	transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
	font-size: 16px;
}

.mobile-nav__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
	color: var(--thm-text-dark);
}

.mobile-nav__language {
	display: flex;
	align-items: center;
}

.mobile-nav__language img {
	border-radius: 50%;
	margin-right: 10px;
}

.mobile-nav__language .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 70px;
}

.mobile-nav__language .bootstrap-select>.dropdown-toggle {
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	border: none;
	outline: none;
	color: var(--thm-text-dark);
	font-size: 14px;
}

.mobile-nav__social {
	display: flex;
	align-items: center;
}

.mobile-nav__social a {
	font-size: 16px;
	color: #fff;
	transition: 500ms;
}

.mobile-nav__social a+a {
	margin-left: 15px;
}

.mobile-nav__social a:hover {
	color: var(--thm-base);
}

.mobile-nav__contact {
	margin-bottom: 0;
	margin-top: 20px;
	margin-bottom: 20px;
}

.mobile-nav__contact li {
	color: var(--thm-text-dark);
	font-size: 12px;
	font-weight: 500;
	position: relative;
	display: flex;
	align-items: center;
}

.mobile-nav__contact li+li {
	margin-top: 15px;
}

.mobile-nav__contact li a {
	color: inherit;
	transition: 500ms;
}

.mobile-nav__contact li a:hover {
	color: var(--thm-base);
}

.mobile-nav__contact li>i {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--thm-base);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 14px;
	margin-right: 10px;
	color: #fff;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 991;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(-110%);
	transition: transform 500ms ease, opacity 500ms ease;
}

.search-popup.active {
	transform: translateY(0%);
}

.search-popup__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--thm-black);
	opacity: 0.75;
	cursor: pointer;
}

.search-popup__content {
	width: 100%;
	max-width: 560px;
}

.search-popup__content form {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	background-color: #fff;
	border-radius: 0px;
	overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
	width: 100%;
	background-color: #fff;
	font-size: 16px;
	border: none;
	outline: none;
	height: 66px;
	padding-left: 30px;
}

.search-popup__content .thm-btn {
	padding: 0;
	width: 68px;
	height: 68px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;
	top: 0;
	right: -1px;
	border-radius: 0;
}

.search-popup__content .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.search-popup__content .thm-btn i {
	margin: 0;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
	/* background-color: var(--thm-base); */
	position: relative;
}

.page-header__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media (max-width: 1199px) {
	.page-header__bg {
		background-position: left center;
	}
}

.page-header .container {
	position: relative;
	z-index: 10;
	padding-top: 118px;
	padding-bottom: 118px;
}

@media (max-width: 480px) {
	.page-header .container {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.page-header .container h2 {
	margin: 0;
	line-height: 1;
	color: #fff;
	font-size: 45px;
	font-weight: 800;
	margin-top: 22px;
}

@media (max-width: 600px) {
	.page-header .container h2 {
		font-size: 40px;
		text-align: center;
	}
}

.thm-breadcrumb {
	display: flex;
	margin-bottom: 0;
	align-items: center;
}

.thm-breadcrumb li {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .2em;
	color: #f5f5f5;
	text-transform: uppercase;
	line-height: 1;
	display: flex;
	align-items: center;
}

.thm-breadcrumb li:not(:last-child)::after {
	content: '/';
	color: #f5f5f5;
	opacity: 0.6;
	position: relative;
	top: -1px;
	margin-left: 13px;
	margin-right: 13px;
}

.thm-breadcrumb li a {
	opacity: 0.6;
	color: inherit;
}

.thm-breadcrumb li span {
	display: block;
	line-height: 1;
	color: var(--thm-base);
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map__default {
	width: 100%;
}

.google-map__default iframe {
	display: block;
	border: none;
	outline: none;
	width: 100%;
	height: 430px;
}

/*--------------------------------------------------------------
# Client Carousel
--------------------------------------------------------------*/
.client-carousel {
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	background-color: #fff;
	background-image: url(../images/shapes/contact-info-shape-1-1.png);
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
}

.client-carousel__has-border-top {
	border-top: 1px solid #e9e7e2;
}

.client-carousel__has-top-shadow {
	box-shadow: 0px -1px 0px 0px RGBA(227, 227, 227, 0.004);
}

.client-carousel .swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
}

.client-carousel img {
	transition: 500ms;
	opacity: 0.3;
	max-width: 100%;
}

.client-carousel img:hover {
	/* background-color: var(--thm-gray);
	opacity: 0.6; */
}

.client-carousel--team-details {
	background-image: url(../images/shapes/contact-info-shape-light-1-1.png);
	background-color: var(--thm-base);
}

.client-carousel--team-details img:hover {
	background-color: #c1180b;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.main-footer {
	background-color: var(--thm-black);
	background-image: url(../images/shapes/footer-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
	background-blend-mode: luminosity;
	padding-top: 126px;
	padding-bottom: 110px;
}

@media (max-width: 600px) {
	.main-footer {
		padding-top: 66px;
	}
}

.main-footer--extra-padding {
	padding-top: 186px;
}

.footer-widget--about .footer-widget__text {
	margin-top: 12px;
}

.footer-widget--about .footer-widget__info {
	margin-top: 35px;
}

.footer-widget__logo {
	position: relative;
	display: inline-flex;
	margin-top: -26px;
	margin-left: -8px;
}

.footer-widget__title {
	margin: 0;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 20px;
	margin-bottom: 35px;
}

.footer-widget__title::before {
	font-size: 12px;
	color: #fff;
	/* color: var(--thm-base); */
	content: '||';
	margin-right: 3px;
}

.footer-widget__menu {
	margin-bottom: 0;
}

.footer-widget__menu li+li {
	margin-top: 6px;
}

.footer-widget__menu li a {
	color: var(--thm-text-dark);
	font-size: 14px;
	line-height: 1.7em;
	transition: all 500ms ease;
}

.footer-widget__menu li a:hover {
	color: #fff;
}

.footer-widget__social {
	display: flex;
	align-items: center;
}

.footer-widget__social a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 41px;
	height: 41px;
	/* background-color: var(--thm-black2); */
	color: #fff;
	font-size: 28px;
	transition: all 500ms ease;
	border-radius: 50%;
}

.footer-widget__social a+a {
	margin-left: 10px;
}

.footer-widget__social a:hover {
	background-color: #fff;
	color: var(--thm-black2);
}

.footer-widget__text {
	margin: 0;
	color: var(--thm-text-dark);
	font-size: 14px;
	line-height: 1.875em;
}

.footer-widget__info li {
	display: flex;
	/* align-items: start; */
	justify-content: start;
	/* color: #fff; */
	color: var(--thm-text-dark);
	font-size: 14px;
	font-weight: 400;
}

.footer-widget__info li+li {
	margin-top: 6px;
}

.footer-widget__info li a {
	color: inherit;
	transition: all 500ms ease;
}

.footer-widget__info li>i {
	color: #fff;
	/* color: var(--thm-base); */
	transition: all 500ms ease;
	margin-right: 15px;
	position: relative;
	top: 8px;
}

.footer-widget__info li:hover {
	/* color: var(--thm-base); */
}

.footer-widget__info li:hover>i {
	color: #fff;
}

.footer-widget__mailchimp input[type=text],
.footer-widget__mailchimp input[type=email],
.footer-widget__mailchimp input[type=phone] {
	display: block;
	width: 100%;
	border: none;
	outline: none;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 3px solid rgba(255, 255, 255, 0.2);
	color: var(--thm-text-dark);
	font-size: 14px;
	padding-bottom: 20px;
}

.footer-widget__mailchimp__text {
	display: flex;
	align-items: center;
	color: var(--thm-text-dark);
	font-size: 14px;
	margin-top: 27px;
	margin-bottom: 0;
}

.footer-widget__mailchimp__text>i {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19px;
	height: 19px;
	border: 1px solid var(--thm-text-dark);
	font-size: 8px;
	color: var(--thm-text-dark);
	border-radius: 50%;
	margin-right: 10px;
}

@media (min-width: 1200px) {
	.footer-widget--newsletter {
		margin-left: 70px;
	}
}

.footer-widget--newsletter .footer-widget__mailchimp {
	margin-top: -10px;
}

.footer-widget--time .footer-widget__text {
	line-height: 2.25em;
	margin-top: -12px;
}

.footer-widget--time .footer-widget__social {
	margin-top: 27px;
}

.footer-widget--links .footer-widget__menu {
	margin-top: -10px;
}

.bottom-footer {
	padding-top: 22px;
	padding-bottom: 22px;
	background-color: var(--thm-black2);
}

.bottom-footer__text {
	margin: 0;
	color: var(--thm-text-dark);
	font-size: 14px;
}

/*--------------------------------------------------------------
# Contact Infos
--------------------------------------------------------------*/
.contact-info-one {
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
	background-color: #fff;
	background-image: url(../images/shapes/contact-info-shape-1-1.png);
	background-repeat: no-repeat;
	background-position: center center;
	padding-top: 70px;
	padding-bottom: 70px;
}

@media (min-width: 992px) {
	.contact-info-one {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.contact-info-one__item {
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	/* flex-wrap: wrap; */
}

@media (max-width: 600px) {
	.contact-info-one__item {
		display: flex;
		justify-content: start;
		text-align: left;
		padding-left: 20px;
	}
}

@media (min-width: 768px) {
	.contact-info-one__item {
		justify-content: center;
		text-align: center;
	}
}

@media (min-width: 992px) {
	.contact-info-one__item {
		text-align: left;
		justify-content: center;
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

.contact-info-one__icon {
	width: 62px;
	height: 62px;
	background-color: var(--thm-base);
	color: #fff;
	font-size: 18px;
	transition: all 0.5s ease;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

@media (max-width: 600px) {
	.contact-info-one__icon {
		width: 55px;
		height: 55px;
		margin-right: 10px;

	}
}

@media (min-width: 768px) {
	.contact-info-one__icon {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

@media (min-width: 992px) {
	.contact-info-one__icon {
		margin-right: 20px;
		margin-bottom: 0px;
	}
}

.contact-info-one__item:hover .contact-info-one__icon {
	background-color: var(--thm-black);
}

.contact-info-one__text {
	margin: 0;
	font-size: 14px;
	line-height: 1.7em;
}

.contact-info-one__link {
	font-weight: 600;
	font-size: 16px;
	color: var(--thm-black);
	line-height: 1.7em;
	transition: all 0.5s ease;
}

@media (max-width: 768px) {
	.contact-info-one__link {
		font-size: 15px;
	}

}

.contact-info-one__link:hover {
	color: var(--thm-base);
}

@media (max-width: 768px) {
	.contact-one {
		padding-top: 50px;
		padding-bottom: 30px;
	}

}


.contact-one {
	background-image: url(../images/shapes/contact-page-form-1-1.png);
	background-repeat: no-repeat;
	background-position: top left;
}

.contact-one__content {
	margin-bottom: 60px;
}

@media (min-width: 992px) {
	.contact-one__content {
		margin-bottom: 0;
	}
}

.contact-one__text {
	margin: 0;
	margin-top: -10px;
}

.contact-one__social {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.contact-one__social a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 41px;
	height: 41px;
	background-color: var(--thm-gray);
	color: var(--thm-black);
	font-size: 12px;
	transition: all 500ms ease;
	border-radius: 50%;
}

.contact-one__social a+a {
	margin-left: 10px;
}

.contact-one__social a:hover {
	background-color: var(--thm-base);
	color: #ffffff;
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog-two {
	background-image: linear-gradient(0deg, #ffffff 0%, var(--thm-gray) 100%);
	position: relative;
}

.blog-two::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/faq-form-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
}

.blog-two .container {
	position: relative;
}

.blog-details__title {
	margin: 0;
	font-size: 28px;
	font-weight: bold;
	line-height: 1.2em;
	margin-bottom: 52px;
}

.blog-details .blog-card__image {
	margin-bottom: 30px;
}

.blog-details .blog-card__meta {
	justify-content: flex-start;
}

.blog-details .blog-card__title {
	margin-bottom: 10px;
	font-size: 30px;
}

.blog-details__content {
	padding-bottom: 55px;
}

.blog-details__content p {
	margin: 0;
}

.blog-details__content p+p {
	margin-top: 30px;
}

.blog-details__meta {
	border-top: 1px solid var(--thm-gray);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
}

.blog-details__social {
	display: flex;
	align-items: center;
}

.blog-details__social a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	background-color: var(--thm-gray);
	color: var(--thm-black);
	font-size: 12px;
	transition: all 500ms ease;
	border-radius: 50%;
}

.blog-details__social a+a {
	margin-left: 10px;
}

.blog-details__social a:hover {
	background-color: var(--thm-base);
	color: #ffffff;
}

.blog-details__tags {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

@media (min-width: 481px) {
	.blog-details__tags {
		margin-bottom: 0;
	}
}

.blog-details__tags span {
	font-size: 18px;
	font-weight: 600;
	color: var(--thm-black);
	display: inline-block;
}

.blog-details__tags a {
	display: inline-block;
	background-color: var(--thm-base);
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	transition: all 500ms ease;
	padding: 8.5px 20px;
	margin-left: 10px;
}

.blog-details__tags a:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.blog-details__author {
	background-color: var(--thm-gray);
	padding: 60px;
	display: flex;
	align-items: center;
	margin-bottom: 55px;
}

@media (max-width: 991px) {
	.blog-details__author {
		flex-wrap: wrap;
	}
}

.blog-details__author__image {
	margin-right: 40px;
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.blog-details__author__image {
		margin-bottom: 0;
	}
}

.blog-details__author__name {
	margin: 0;
	color: var(--thm-black);
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 30px;
}

.blog-details__author__text {
	margin: 0;
}

.blog-details__comment__item {
	display: flex;
	align-items: flex-start;
	position: relative;
	border-bottom: 1px solid var(--thm-gray);
	padding-bottom: 60px;
	margin-bottom: 60px;
}

@media (max-width: 991px) {
	.blog-details__comment__item {
		flex-wrap: wrap;
	}
}

.blog-details__comment__image {
	margin-right: 0px;
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.blog-details__comment__image {
		margin-right: 40px;
		margin-bottom: 0;
	}
}

.blog-details__comment__name {
	margin: 0;
	font-size: 20px;
	color: var(--thm-black);
	font-weight: bold;
	margin-top: -4px;
	margin-bottom: 23px;
}

.blog-details__comment__text {
	margin: 0;
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.blog-details__comment__text {
		margin-bottom: 0;
	}
}

.blog-details__comment .thm-btn {
	position: relative;
	font-size: 12px;
	padding: 13px 20px;
}

.blog-details__comment .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

@media (min-width: 992px) {
	.blog-details__comment .thm-btn {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.blog-sidebar .list-unstyled {
	margin-bottom: 0;
}

.blog-sidebar__item+.blog-sidebar__item {
	margin-top: 30px;
}

.blog-sidebar__item {
	padding: 50px;
	background-color: var(--thm-gray);
}

.blog-sidebar__item--search {
	padding: 0;
	background-color: var(--thm-base);
}

.blog-sidebar__title {
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	color: var(--thm-black);
	margin-bottom: 26px;
}

.blog-sidebar__search {
	position: relative;
}

.blog-sidebar__search input[type=text],
.blog-sidebar__search input[type=search] {
	width: 100%;
	height: 70px;
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border: none;
	outline: none;
	padding-left: 50px;
	padding-right: 50px;
}

.blog-sidebar__search__btn {
	position: absolute;
	top: 50%;
	right: 50px;
	background-color: rgba(0, 0, 0, 0);
	transform: translateY(-50%);
	color: #fff;
	border: none;
	outline: none;
	font-size: 16px;
	width: auto;
	padding: 0;
}

.blog-sidebar__post li+li {
	margin-top: 30px;
}

.blog-sidebar__post__item {
	display: flex;
	align-items: flex-start;
}

.blog-sidebar__post__image {
	margin-right: 20px;
}

.blog-sidebar__post__title {
	font-size: 16px;
	font-weight: 600;
	color: var(--thm-black);
	margin: 0;
	line-height: 1.625em;
	margin-top: 9px;
}

.blog-sidebar__post__title a {
	color: inherit;
	transition: all 0.5s ease;
}

.blog-sidebar__post__title a:hover {
	color: var(--thm-base);
}

.blog-sidebar__post__author {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--thm-color);
	line-height: 1;
}

.blog-sidebar__post__author i {
	color: var(--thm-base);
	margin-right: 4px;
}

.blog-sidebar__categories {
	margin-top: -12px;
	margin-bottom: -17px !important;
}

.blog-sidebar__categories li {
	margin-left: -20px;
	margin-right: -20px;
}

.blog-sidebar__categories li a {
	font-size: 15px;
	display: flex;
	line-height: 1.6em;
	color: var(--thm-color);
	transition: all 500ms ease;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	padding: 10px 20px;
}

.blog-sidebar__categories li a::after {
	content: '\f105';
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

.blog-sidebar__categories li:hover a {
	color: var(--thm-black);
	text-shadow: 0 0 1px currentColor;
	background-color: #fff;
}

.blog-sidebar__categories li:hover a::after {
	text-shadow: none;
}

.blog-sidebar__tags {
	display: flex;
	flex-wrap: wrap;
	margin: -5px;
}

.blog-sidebar__tags li {
	margin: 5px;
}

.blog-sidebar__tags li a {
	display: block;
	background-color: #fff;
	color: var(--thm-black);
	font-size: 12px;
	font-weight: 600;
	transition: all 500ms ease;
	padding: 8.5px 20px;
}

.blog-sidebar__tags li a:hover {
	background-color: var(--thm-base);
	color: #fff;
}

.blog-three {
	padding-top: 120px;
}

@media (min-width: 992px) {
	.blog-three {
		padding-top: 240px;
	}
}

.blog-three__top {
	margin-bottom: 50px;
}

@media (min-width: 992px) {
	.blog-three__top {
		margin-bottom: 0;
	}
}

.blog-three .thm-btn {
	background-color: var(--thm-gray);
	color: var(--thm-black);
	padding: 23px 60px;
}

.blog-three .thm-btn:hover {
	color: #fff;
	background-color: var(--thm-base);
}

@media (min-width: 992px) {
	.blog-three__btns {
		display: flex;
		justify-content: flex-end;
		padding-top: 30px;
	}
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team-about {
	position: relative;
}

.team-about::before {
	content: "";
	width: 100%;
	height: 530px;
	background-color: var(--thm-gray);
	background-image: url(../images/shapes/team-about-bg-1-1.png);
	background-size: cover;
	mix-blend-mode: luminosity;
	position: absolute;
	top: 0;
	left: 0;
}

.team-details {
	background-image: url(../images/shapes/contact-page-form-1-1.png);
	background-repeat: no-repeat;
	background-position: top left;
}

.team-details__image {
	margin-bottom: 50px;
}

@media (min-width: 992px) {
	.team-details__image {
		margin-bottom: 0;
	}
}

.team-details__image img {
	max-width: 100%;
}

.team-details .block-title {
	margin-bottom: 40px;
}

.team-details__content {
	position: relative;
}

@media (min-width: 1200px) {
	.team-details__content {
		padding-left: 70px;
	}
}

.team-details__floated {
	text-transform: uppercase;
	color: var(--thm-gray);
	font-size: 100px;
	font-weight: 400;
	letter-spacing: 0.2em;
	position: absolute;
	left: calc(100% - 95px);
	top: calc(50% + 15px);
	transform: rotate(90deg);
	transform-origin: top center;
	display: none;
}

@media (min-width: 992px) {
	.team-details__floated {
		display: block;
	}
}

.team-details__highlight {
	text-transform: uppercase;
	margin: 0;
	color: var(--thm-base);
	line-height: 1.5em;
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 40px;
}

.team-details__text {
	margin: 0;
	font-size: 18px;
	line-height: 1.88em;
}

.team-details__text+.team-details__text {
	margin-top: 30px;
}

.team-details__social {
	display: flex;
	align-items: center;
	margin-bottom: 33px;
}

.team-details__social a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 41px;
	height: 41px;
	background-color: var(--thm-gray);
	color: var(--thm-black);
	font-size: 12px;
	transition: all 500ms ease;
	border-radius: 50%;
}

.team-details__social a+a {
	margin-left: 10px;
}

.team-details__social a:hover {
	background-color: var(--thm-base);
	color: #ffffff;
}

.team-progress__title {
	margin: 0;
	font-size: 20px;
	font-weight: 800;
	color: var(--thm-black);
	margin-bottom: 10px;
	margin-top: -3px;
}

.team-progress__bar {
	width: 100%;
	height: 13px;
	border-radius: 6.5px;
	background-color: var(--thm-gray);
	position: relative;
}

.team-progress__bar__inner {
	position: absolute;
	background-color: var(--thm-base);
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 6.5px;
	width: 0;
	transition: all 1500ms ease;
}

.team-progress__bar__text {
	font-size: 14px;
	color: var(--thm-color);
	position: absolute;
	bottom: 100%;
	right: 0;
	line-height: 1;
	padding-bottom: 14px;
}

.team-progress__item+.team-progress__item {
	margin-top: 30px;
}

/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq-form {
	background-image: linear-gradient(0deg, #ffffff 0%, var(--thm-gray) 100%);
	position: relative;
}

.faq-form::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/faq-form-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
}

.faq-form .container {
	position: relative;
}

@media (min-width: 992px) {
	.faq-form .container {
		max-width: 800px;
	}
}

.accrodion {
	border: 1px solid var(--thm-border-color);
	transition: all 500ms ease;
	min-height: 100px
}

.accrodion.active {
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.accrodion-title {
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 26.5px;
	padding-bottom: 27px;
	cursor: pointer;
}

.accrodion-title h4 {
	margin: 0;
	color: var(--thm-black);
	font-size: 18px;
	font-weight: 600;
	line-height: 1.2em;
	position: relative;
	transition: all 500ms ease;
}

.accrodion.active .accrodion-title h4 {
	/* color: var(--thm-base); */
}

.accrodion-icon {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	width: 12px;
	height: 12px;
}

.accrodion-icon::before,
.accrodion-icon::after {
	content: "";
	width: 12px;
	height: 2px;
	background-color: var(--thm-black);
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: background-color 500ms ease, opacity 500ms ease;
	opacity: 1;
}

.accrodion-icon::after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.accrodion.active .accrodion-icon::before {
	background-color: var(--thm-base);
}

.accrodion.active .accrodion-icon::after {
	opacity: 0;
}

.accrodion-content {
	padding-left: 40px;
	padding-right: 40px;
}

.accrodion-content p {
	padding-bottom: 31px;
	margin: 0;
}

.accrodion+.accrodion {
	margin-top: 20px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.service-card__image {
	position: relative;
	overflow: hidden;
}

.service-card__image>img {
	width: 100%;
	transform: scale(1);
	transition: transform 500ms ease;
}

.service-card__image>a {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: rgba(var(--thm-black-rgb), 0.4);
	position: absolute;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transform: translateY(-20%);
	transition: opacity 500ms ease, transform 500ms ease;
}

.service-card__image>a::before,
.service-card__image>a::after {
	content: "";
	width: 32px;
	height: 2px;
	background-color: #fff;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.service-card__image>a::after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.service-card:hover .service-card__image>a {
	opacity: 1;
	transform: translateY(0);
}

.service-card:hover .service-card__image>img {
	transform: scale(1.05);
}

.service-card__icon {
	width: 92px;
	height: 92px;
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
	font-size: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: var(--thm-base);
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	top: -46px;
	margin-bottom: -46px;
	position: relative;
	z-index: 10;
	transition: all 500ms ease;
}

.service-card:hover .service-card__icon {
	background-color: var(--thm-base);
	color: #fff;
}

.service-card__content {
	padding-left: 20px;
	padding-right: 20px;
	position: relative;
	margin-top: -20px;
}

.service-card__content__inner {
	background-color: #fff;
	border-bottom: 2px solid var(--thm-gray);
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	position: relative;
	transition: all 500ms ease;
	height: 300px;
}

.service-card__content__inner::after {
	content: '';
	width: 100%;
	height: 3px;
	position: absolute;
	bottom: -3px;
	left: 0;
	transform-origin: left center;
	transform: scale(0, 1);
	background-color: var(--thm-black);
	transition: transform 500ms ease;
}

.service-card:hover .service-card__content__inner {
	box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.service-card:hover .service-card__content__inner::after {
	transform: scale(1, 1);
	transform-origin: right center;
}

.service-card__title {
	margin: 0;
	font-size: 20px;
	font-weight: 800;
	color: var(--thm-black);
	margin-top: 25px;
	margin-bottom: 16px;
}

.service-card__title a {
	color: inherit;
	transition: all 500ms ease;
}

.service-card__title a:hover {
	color: var(--thm-base);
}

.service-card__link {
	width: 40px;
	height: 40px;
	background-color: var(--thm-gray);
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--thm-color);
	transition: all 500ms ease;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	bottom: -21px;
	margin-top: -21px;
	z-index: 11;
}

.service-card__link:hover {
	color: #fff;
	background-color: var(--thm-black);
}

.service-card:hover .service-card__link {
	color: #fff;
	background-color: var(--thm-black);
}

.service-details__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 34px;
	font-weight: bold;
	margin-top: -7px;
	margin-bottom: 20px;
}

.service-details__image {
	position: relative;
	margin-bottom: 40px;
}

.service-details__image img {
	max-width: 100%;
}

.service-details__icon {
	position: absolute;
	bottom: -46px;
	right: 30px;
	width: 92px;
	height: 92px;
	background-color: var(--thm-base);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 43px;
	border-radius: 50%;
}

.service-details__content img {
	max-width: 100%;
}

.service-details__content p {
	margin: 0;
}

.service-details__content p+p {
	margin-top: 30px;
}

.service-details__content .row {
	margin-top: 50px;
}

.service-details .service-details__text {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 35px;
}

.service-details__subtitle {
	margin: 0;
	font-size: 30px;
	font-weight: bold;
	color: var(--thm-black);
	margin-top: -7px;
	margin-bottom: 25px;
}

.service-details__list {
	margin-bottom: 0;
}

.service-details__list li {
	position: relative;
	padding-left: 30px;
	font-size: 16px;
	line-height: 1.875em;
	color: var(--thm-color);
	font-weight: 400;
}

.service-details__list li+li {
	margin-top: 6px;
}

.service-details__list li>i {
	position: absolute;
	top: 9px;
	left: 0;
	color: var(--thm-base);
	font-size: 16px;
}

.service-sidebar__item+.service-sidebar__item {
	margin-top: 30px;
}

.service-sidebar__menu {
	margin: 0;
	padding: 0;
	list-style: none;
}

.service-sidebar__menu li+li {
	margin-top: 10px;
}

.service-sidebar__menu li a {
	font-size: 16px;
	color: var(--thm-black);
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	padding-left: 40px;
	padding-right: 30px;
	background-color: var(--thm-gray);
	transition: all 0.5s ease;
	align-items: center;
	padding-top: 14.5px;
	padding-bottom: 14.5px;
}

.service-sidebar__menu li a:hover {
	color: #fff;
	background-color: var(--thm-base);
}

.service-sidebar__menu li a::after {
	content: '\f105';
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

.service-sidebar__menu li.active>a {
	color: #fff;
	background-color: var(--thm-base);
}

.service-sidebar__item--contact {
	background-color: var(--thm-base);
	position: relative;
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 60px;
	padding-bottom: 48px;
	text-align: center;
}

.service-sidebar__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-blend-mode: luminosity;
	background-color: var(--thm-base);
	opacity: 0.1;
}

.service-sidebar__contact {
	position: relative;
}

.service-sidebar__separator {
	margin: 0;
	margin-left: 10px;
	margin-right: 10px;
	opacity: 0.1;
	background-color: #fff;
}

.service-sidebar__icon {
	width: 78px;
	height: 78px;
	background-color: var(--thm-black);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #fff;
	font-size: 24px;
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	transition: all 500ms ease;
}

.service-sidebar__item--contact:hover .service-sidebar__icon {
	background-color: #fff;
	color: var(--thm-black);
}

.service-sidebar__title {
	font-size: 30px;
	line-height: 1.33em;
	color: #fff;
	max-width: 156px;
	margin-left: auto;
	margin-right: auto;
	font-weight: bold;
}

.service-sidebar__item--contact .service-sidebar__title {
	margin-top: 27px;
	margin-bottom: 12px;
}

.service-sidebar__tagline {
	margin: 0;
	font-size: 16px;
	color: #fff;
	opacity: 0.7;
}

.service-sidebar__item--contact .service-sidebar__tagline {
	margin-top: 21px;
	margin-bottom: 12px;
}

.service-sidebar__phone {
	font-size: 24px;
	font-weight: bold;
	color: #fff;
	transition: all 500ms ease;
}

.service-sidebar__phone:hover {
	color: var(--thm-black);
}

.service-one {
	position: relative;
}

[class*=service-one__icon-] {
	position: absolute;
}

.service-one__icon-1 {
	top: 105px;
	left: 125px;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.service-one__icon-2 {
	top: 131px;
	right: 350px;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.service-one__icon-3 {
	bottom: 110px;
	right: 50px;
	animation-name: bubbleMover;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 8s;
}

.service-two {
	position: relative;
	z-index: 10;
}

.service-two__shape {
	background-image: url(../images/shapes/service-2-1.png);
	width: 158px;
	height: 137px;
	background-repeat: no-repeat;
	position: absolute;
	left: 230px;
	top: 70px;
	animation-name: bubbleMover;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 8s;
}

.service-card-two {
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
	padding: 50px;
	position: relative;
}

.service-card-two::before {
	content: '';
	width: 165px;
	height: 60px;
	background-color: var(--thm-gray);
	top: 50px;
	left: 0;
	position: absolute;
	background-image: linear-gradient(90deg, #ffffff 0%, var(--thm-gray) 100%);
}

.service-card-two__icon {
	display: flex;
	margin-bottom: 30px;
}

.service-card-two__icon i {
	margin-left: auto;
	font-size: 60px;
	color: var(--thm-base);
}

.service-card-two__title {
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	color: var(--thm-black);
	line-height: 1.2em;
	margin-top: -3px;
	margin-bottom: 20px;
}

.service-card-two__title a {
	color: inherit;
	transition: all 500ms ease;
}

.service-card-two__title a:hover {
	color: var(--thm-base);
}

.service-card-two__text {
	margin: 0;
	margin-bottom: 19px;
}

.service-card-two__link {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	color: var(--thm-black);
	letter-spacing: .2em;
	display: inline-block;
	padding-bottom: 2px;
	border-bottom: 3px solid var(--thm-black);
	transition: all 500ms ease;
}

.service-card-two__link:hover {
	color: var(--thm-base);
	border-color: var(--thm-base);
}

.service-three {
	position: relative;
	background-color: var(--thm-gray);
	counter-reset: serviceNumber;
}

.service-three::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/faq-form-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
}

.service-three__top {
	margin-bottom: 50px;
}

@media (min-width: 992px) {
	.service-three__top {
		margin-bottom: 0px;
	}
}

.service-three__top__text {
	margin: 0;
}

@media (min-width: 992px) {
	.service-three__top__text {
		margin-top: 17px;
		margin-left: 70px;
	}
}

.service-three__carousel {
	margin-top: 10px;
}

.service-three__carousel .owl-stage-outer {
	overflow: visible;
}

.service-three__carousel .owl-item {
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease;
}

.service-three__carousel .owl-item.active {
	opacity: 1;
	visibility: visible;
}

.service-three__carousel .owl-dots {
	margin-top: 60px;
}

@media (max-width: 991px) {
	.service-three__carousel .owl-dots {
		display: none;
	}
}

.service-three__carousel .owl-nav {
	display: none;
}

.service-card-three {
	background-color: #fff;
	position: relative;
	counter-increment: serviceNumber;
	height: 450px
}

@media (max-width: 991px) {
	.service-card-three {

		height: 350px
	}
}

.service-card-three__inner {
	position: relative;
	overflow: hidden;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 38px;
}

.service-card-three__inner::before {
	content: '';
	width: 194px;
	height: 194px;
	background-color: var(--thm-black);
	position: absolute;
	left: -44px;
	top: -96px;
	border-radius: 50%;
	transition: all 500ms ease;
}

.service-card-three__inner::after {
	/* content: counters(serviceNumber, ".", decimal-leading-zero);
	font-size: 45px;
	font-weight: bold;
	color: var(--thm-gray);
	text-transform: uppercase;
	line-height: 1em;
	position: absolute;
	top: 30px;
	right: 30px; */
}

.service-card-three:hover .service-card-three__inner::before {
	/* background-color: var(--thm-base); */
}

.service-card-three__icon {
	position: relative;
	padding-top: 23px;
	margin-bottom: 50px;
}

.service-card-three__icon i {
	color: white;
	transition: all 500ms ease;
	font-size: 47px;
}

.service-card-three:hover .service-card-three__icon i {
	color: #fff;
}

.service-card-three__title {
	margin: 0;
	font-size: 20px;
	color: var(--thm-black);
	font-weight: 800;
	position: relative;
	margin-bottom: 16px;
}

.service-card-three__title a {
	color: inherit;
	transition: all 500ms ease;
}

.service-card-three__title a:hover {
	color: var(--thm-base);
}

.service-card-three__text {
	margin: 0;
	position: relative;
}

.service-card-three__link {
	display: block;
	position: absolute;
	right: 40px;
	bottom: -20px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: var(--thm-text-dark);
	width: 40px;
	height: 40px;
	transition: all 0.5s ease;
}

.service-card-three__link:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.service-card-three:hover .service-card-three__link {
	background-color: var(--thm-black);
	color: #fff;
}

.service-four {
	padding-top: 120px;
}

@media (min-width: 992px) {
	.service-four {
		padding-top: 0;
	}
}

.service-four__inner {
	position: relative;
	z-index: 10;
	background-color: #fff;
	border-top: 15px solid var(--thm-base);
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (min-width: 992px) {
	.service-four__inner {
		margin-top: -60px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.service-four [class*=col-]:not(:first-child) {
	border-top: 1px solid var(--thm-gray);
}

@media (min-width: 992px) {
	.service-four [class*=col-]:not(:first-child) {
		border-top: 0;
		border-left: 1px solid var(--thm-gray);
	}
}

@media (min-width: 1200px) {
	.service-four [class*=col-]:nth-child(2n) .service-card-four {
		position: relative;
		left: 20 px;
	}
}

.service-card-four {
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 60px;
	padding-right: 60px;
	text-align: center;
}

@media (min-width: 992px) {
	.service-card-four {
		text-align: left;
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

.service-card-four__icon {
	position: relative;
	display: inline-block;
	margin-bottom: 11px;
}

.service-card-four__icon::after {
	content: '';
	width: 58px;
	height: 58px;
	background-color: var(--thm-gray);
	position: absolute;
	top: 0;
	right: -38px;
	border-radius: 50%;
}

.service-card-four__icon i {
	font-size: 60px;
	color: var(--thm-base);
	position: relative;
	z-index: 10;
}

.service-card-four__title {
	margin: 0;
	font-size: 20px;
	color: var(--thm-black);
	font-weight: bold;
	margin-bottom: 13px;
}

.service-card-four__title a {
	color: inherit;
	transition: all 500ms ease;
}

.service-card-four__title a:hover {
	color: var(--thm-base);
}

.service-card-four__text {
	margin: 0;
}

@media (min-width: 992px) {
	.service-card-four__text {
		max-width: 241px;
	}
}

.service-five {
	background-image: linear-gradient(0deg, #ffffff 0%, var(--thm-gray) 100%);
	position: relative;
}

.service-five::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/faq-form-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
}

@media (min-width: 992px) {
	.service-five__text {
		margin-left: 100px;
		padding-top: 27px;
	}
}

.service-five__top {
	margin-bottom: 40px;
}

@media (min-width: 992px) {
	.service-five__top {
		margin-bottom: 0;
	}
}

.service-card-five {
	text-align: center;
	background-color: #fff;
	/* box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07); */
	position: relative;
	padding-top: 50px;
	padding-bottom: 47px;
	padding-left: 10px;
	padding-right: 10px;
	transform: translateY(0);
	transition: transform 500ms ease;
	margin: 20px 0px;
	height: 350px;
}

.service-card-five:hover {
	transform: translateY(-10px);
}

.service-card-five::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/service-card-5-s-1.png);
	background-position: top left;
	background-repeat: no-repeat;
	transform: scale(1, 0);
	transition: transform 500ms ease;
	transform-origin: top center;
}

.service-card-five:hover::before {
	transform: scale(1, 1);
}

.service-card-five::after {
	content: '';
	width: 100%;
	height: 10px;
	background-color: var(--thm-base);
	position: absolute;
	top: 100%;
	left: 0;
	transform: scale(1, 0);
	transform-origin: bottom center;
	transition: transform 500ms ease;
}

.service-card-five:hover::after {
	transform: scale(1, 1);
}

.service-card-five__icon {
	width: 109px;
	height: 109px;
	background-color: var(--thm-base);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	border-radius: 50%;
	color: #fff;
	transition: all 500ms ease;
	margin-bottom: 30px;
}

.service-card-five__icon i {
	font-size: 64px;
}

.service-card-five:hover .service-card-five__icon {
	background-color: var(--thm-black);
	color: #fff;
}

.service-card-five__title {
	margin: 0;
	font-size: 17px;
	font-weight: bold;
	position: relative;
	color: var(--thm-black);
	margin-bottom: 17px;
}

.service-card-five__title a {
	color: inherit;
	transition: all 500ms ease;
}

.service-card-five__text {
	position: relative;
	margin: 0;
	margin-bottom: -3px;
	max-width: 161px;
	margin-left: auto;
	margin-right: auto;
	color: var(--thm-color);
}

/*--------------------------------------------------------------
# Career
--------------------------------------------------------------*/
.career-one__item {
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 43px;
	padding-bottom: 43px;
	border: 1px solid var(--thm-border-color);
	text-align: center;
	transition: all 500ms ease;
	height: 100%;
}

.career-one__item:hover {
	box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

@media (min-width: 992px) {
	.career-one__item {
		/* display: flex;
		align-items: center; */
		text-align: center;
	}

	.career-one__item2 {
		display: flex;
		align-items: center;
		text-align: left;
	}
}

@media (min-width: 1200px) {
	.career-one__item {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.career-one__icon {
	align-items: center;
	width: 102px;
	height: 102px;
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
	color: var(--thm-base);
	font-size: 62px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 50%;
	transition: all 500ms ease;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	text-align: center;
}

@media (min-width: 992px) {
	.career-one__icon {
		/* margin: 0;
		flex-shrink: 0;
		margin-right: 40px; */
		text-align: center;
	}

	.career-one__icon2 {
		margin: 0 40px 0 0;
	}
}

.career-one__item:hover .career-one__icon {
	background-color: var(--thm-base);
	color: #fff;
}

.career-one__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 20px;
	font-weight: 800;
	line-height: 1.2em;
	margin-bottom: 16px;
}

.career-one__title a {
	color: inherit;
}

.career-one__title a:hover {
	color: var(--thm-base);
}

.career-one__text {
	margin: 0;
	margin-bottom: 20px;
}

@media (min-width: 992px) {
	.career-one__tuxt {
		margin-bottom: 0;
		max-width: 596px;
	}
}

@media (min-width: 992px) {
	.career-one__btns {
		flex-shrink: 0;
		margin-left: auto;
	}
}

.career-one .thm-btn {
	font-size: 12px;
	padding: 23px 40px;
}

.career-one .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

@media(max-width:1199px) {
	.career-about img {
		width: 100%;
	}
}

@media(max-width:991px) {
	.career-about img {
		width: auto;
	}
}

.career-about__content {
	padding-top: 50px;
}

@media (min-width: 992px) {
	.career-about__content {
		padding-top: 30px;
	}
}

.career-about__highlight {
	margin: 0;
	color: var(--thm-base);
	font-size: 18px;
	line-height: 1.667em;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 30px;
}

.career-about__text {
	margin: 0;
}

/*--------------------------------------------------------------
# Call to action
--------------------------------------------------------------*/
.call-to-action-one--has-bottom-margin {
	margin-bottom: -60px;
}

.call-to-action-one__inner {
	position: relative;
	background-color: var(--thm-base);
	text-align: center;
	padding-top: 80px;
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.call-to-action-one__inner {
		display: flex;
		justify-content: space-between;
		text-align: left;
		align-items: center;
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (min-width: 1200px) {
	.call-to-action-one__inner {
		padding-left: 80px;
		padding-right: 80px;
	}
}

.call-to-action-one__bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-attachment: fixed;
	opacity: 0.1;
	background-color: var(--thm-base);
	background-position: center center;
}

.call-to-action-one__title {
	margin: 0;
	color: #fff;
	font-size: 30px;
	line-height: 1.2em;
	font-weight: bold;
	position: relative;
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.call-to-action-one__title {
		margin-bottom: 0;
		max-width: 467px;
		font-size: 36px;
	}
}

.call-to-action-one__btns {
	position: relative;
	flex-shrink: 0;
}

.call-to-action-one .thm-btn {
	background-color: #fff;
	color: var(--thm-black);
	padding: 23px 60px;
}

.call-to-action-one .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.call-to-action-two {
	background-color: var(--thm-base);
	background-repeat: no-repeat;
	background-position: top right;
	padding-top: 80px;
}

@media (min-width: 992px) {
	.call-to-action-two {
		padding-top: 0;
		background-image: url(../images/shapes/cta-2-shape-1.png);
	}
}

.call-to-action-two__image img {
	max-width: 100%;
}

@media (min-width: 992px) {
	.call-to-action-two__image img {
		max-width: none;
		float: right;
		clip-path: polygon(0 0, 100% 0, 94% 100%, 0 100%);
	}
}

.call-to-action-two__list {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style-type: none;
	margin-bottom: 7px;
}

.call-to-action-two__list li {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: .1em;
	color: #fff;
	opacity: 0.7;
}

.call-to-action-two__list li:not(:last-child)::after {
	content: '/';
	margin-left: 15px;
	margin-right: 15px;
}

.call-to-action-two__title {
	margin: 0;
	font-size: 40px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 30px;
}

.call-to-action-two .thm-btn {
	padding: 23px 60px;
	background-color: #fff;
	color: var(--thm-black);
}

.call-to-action-two .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.call-to-action-two__arrow {
	position: relative;
	left: 20px;
	top: -15px;
	animation: shapeMover 5s linear 0s infinite;
	display: inline-block;
}

.call-to-action-two__arrow svg {
	fill: #fff;
	width: 80px;
	transform: rotate(120deg);
}

.call-to-action-two__content {
	padding-top: 43px;
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.call-to-action-two__content {
		padding-top: 73px;
		padding-bottom: 0;
		padding-left: 30px;
	}
}

.call-to-action-three {
	position: relative;
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
	text-align: center;
	padding-top: 120px;
	padding-bottom: 120px;
}

@media (min-width: 992px) {
	.call-to-action-three {
		padding-bottom: 180px;
	}
}

.call-to-action-three::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: var(--thm-black);
	opacity: 0.6;
}

.call-to-action-three .container {
	position: relative;
}

.call-to-action-three__title {
	margin: 0;
	font-size: 45px;
	font-weight: 800;
	color: #fff;
	line-height: 1.2em;
}

@media (min-width: 768px) {
	.call-to-action-three__title {
		font-size: 55px;
	}
}

@media (min-width: 992px) {
	.call-to-action-three__title {
		font-size: 65px;
		max-width: 735px;
		margin-left: auto;
		margin-right: auto;
	}
}

.call-to-action-four {
	background-color: #fff;
}

@media (min-width: 992px) {
	.call-to-action-four {
		background-color: var(--thm-gray);
	}
}

.call-to-action-four__inner {
	position: relative;
	background-color: #fff;
}

.call-to-action-four__text {
	margin: 0;
	font-size: 18px;
	color: var(--thm-black);
	padding-bottom: 9px;
	padding-top: 10px;
}

.call-to-action-four__text a {
	font-weight: bold;
	color: var(--thm-base);
	transition: all 500ms ease;
	border-bottom: 2px solid var(--thm-black);
	line-height: 1em;
}

.call-to-action-four__text a:hover {
	color: var(--thm-black);
}

.call-to-action-four__shape-1,
.call-to-action-four__shape-2 {
	width: 468px;
	height: 383px;
	position: absolute;
	display: none;
}

@media (min-width: 992px) {

	.call-to-action-four__shape-1,
	.call-to-action-four__shape-2 {
		display: block;
	}
}

.call-to-action-four__shape-1 {
	bottom: -1px;
	right: calc(100% - 104px);
	background-image: url(../images/shapes/cta-4-1.png);
}

.call-to-action-four__shape-2 {
	bottom: -1px;
	left: calc(100% - 104px);
	background-image: url(../images/shapes/cta-4-2.png);
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-one {
	background-image: url(../images/shapes/contact-page-form-1-1.png);
	background-repeat: no-repeat;
	background-position: top left;
}

.about-one__image {
	position: relative;
}

.about-one__image img {
	max-width: 100%;
}

.about-one__image__caption {
	position: relative;
	bottom: 100%;
	left: 0;
	width: 100%;
	background-color: var(--thm-base);
	text-align: center;
	color: #fff;
	font-size: 22px;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
	line-height: 1.36364em;
	padding-top: 38px;
	padding-bottom: 42px;
}

.about-one__image__caption::after {
	/* content: '';
	position: absolute;
	top: 100%;
	left: 60px;
	width: 70px;
	height: 40px;
	background-color: var(--thm-base);
	clip-path: polygon(0 0, 0 100%, 100% 0); */
}

.about-one__image__caption span {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 315px;
}

.about-one__text {
	margin: 0;
	font-size: 18px;
	line-height: 1.889em;
	margin-top: -10px;
	margin-bottom: 40px;
	max-width: 502px;
}

@media (min-width: 768px) {
	.about-one__content [class*=col-]:last-child .about-one__box {
		margin-left: -20px;
	}
}

.about-one__box i {
	font-size: 60px;
	color: var(--thm-base);
}

.about-one__box__content {
	max-width: 216px;
}

.about-one__box__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 20px;
	line-height: 1.5em;
	font-weight: bold;
	margin-top: 9px;
	margin-bottom: 30px;
}

.about-one__box__text {
	margin: 0;
}

.benefit-one {
	position: relative;
	background-image: url(../images/shapes/benefit-circle-1-1.png);
	background-repeat: no-repeat;
	background-position: top right;
	overflow: hidden;
}

.benefit-one__shape-1 {
	width: 574px;
	height: 100%;
	background-color: var(--thm-base);
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: bottom center;
	clip-path: polygon(0 0, 100% 0, 64% 100%, 0% 100%);
}

.benefit-one__shape-1::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--thm-base);
	opacity: 0.9;
}

@media (max-width: 1199px) {
	.benefit-one__shape-1 {
		display: none;
	}
}

.benefit-one__shape-2 {
	position: absolute;
	left: 604px;
	top: 0;
	width: 100px;
	height: 100%;
	background-color: var(--thm-gray);
	transform: skewX(-15deg);
	transform-origin: left top;
}

@media (max-width: 1199px) {
	.benefit-one__shape-2 {
		display: none;
	}
}

.benefit-one .container {
	position: relative;
}

.benefit-one__image {
	position: relative;
}

.benefit-one__image img {
	position: relative;
	z-index: 9;
	max-width: 100%;
}

.benefit-one__image::before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	background-image: linear-gradient(0deg, var(--thm-black) 0%, rgba(var(--thm-black-rgb), 0) 100%);
	z-index: 10;
}

@media (min-width: 1200px) {
	.benefit-one__image {
		right: 70px;
	}
}

.benefit-one__image__caption {
	position: absolute;
	z-index: 11;
	bottom: 0;
	left: 0;
	padding: 60px;
}

.benefit-one__image__title {
	font-size: 45px;
	color: var(--thm-base);
	font-weight: 600;
}

.benefit-one__image__text {
	margin: 0;
	font-size: 18px;
	line-height: 1.66667em;
	color: #fff;
}

.benefit-one__text {
	margin: 0;
	margin-top: -10px;
	margin-bottom: 45px;
}

.benefit-one__box {
	position: relative;
	display: flex;
	align-items: flex-start;
}

.benefit-one__box__icon {
	position: relative;
	margin-right: 45px;
}

.benefit-one__box__icon::before {
	content: '';
	width: 61px;
	height: 61px;
	background-color: var(--thm-gray);
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	transform: scale(1);
	transform-origin: right center;
	transition: transform 500ms ease;
}

.benefit-one__box__icon i {
	margin-left: 20px;
	margin-top: 20px;
	display: block;
	font-size: 60px;
	color: var(--thm-base);
	position: relative;
	transition: all 500ms ease;
}

.benefit-one__box__title {
	margin: 0;
	font-size: 20px;
	line-height: 1.5em;
	color: var(--thm-black);
	font-weight: 700;
	margin-bottom: 11px;
}

.benefit-one__box__text {
	margin: 0;
}

.benefit-one__box:hover .benefit-one__box__icon::before {
	transform: scale(0.85);
}

.benefit-one__box+.benefit-one__box {
	margin-top: 20px;
}

.about-two__image {
	position: relative;
	display: inline-block;
}


.about-two__image2 {
	position: relative;
	display: inline-block;
}

.about-two__image::before {
	content: '';
	/* border: 2px solid var(--thm-base); */
	position: absolute;
	top: 30px;
	left: 30px;
	right: 30px;
	bottom: 30px;
}

.about-two__image img {
	max-width: 100%;
}

.about-two__text {
	margin: 0;
	margin-top: -10px;
}

.about-two__list {
	margin-bottom: 0;
	margin-top: 30px;
}

.about-two__list li {
	position: relative;
	padding-left: 30px;
	font-size: 16px;
	line-height: 1.875em;
	/* color: var(--thm-black); */
	/* font-weight: 500; */
}

.about-two__list li+li {
	margin-top: 6px;
}

.about-two__list li>i {
	position: absolute;
	top: 9px;
	left: 0;
	color: var(--thm-base);
	font-size: 16px;
}

.about-three__image img {
	max-width: 100%;
}

.about-three__text {
	max-width: 871px;
	margin-left: auto;
	margin-right: auto;
}

.about-four {
	position: relative;
}

.about-four__shape {
	position: absolute;
	top: 97px;
	left: 146px;
	width: 97px;
	height: 133px;
	background-image: url(../images/shapes/about-4-s-1.png);
	background-repeat: no-repeat;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.about-four .block-title {
	margin-bottom: 30px;
}

.about-four__text {
	margin: 0;
	margin-bottom: 39px;
	max-width: 501px;
}

.about-four__box {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	max-width: 501px;
}

.about-four__box__icon {
	position: relative;
	margin-right: 17px;
	padding-top: 10px;
	padding-left: 20px;
}

.about-four__box__icon::before {
	content: '';
	width: 60px;
	height: 60px;
	background-color: var(--thm-gray);
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 50%;
}

.about-four__box__icon i {
	font-size: 62px;
	color: var(--thm-base);
	position: relative;
}

.about-four__box__title {
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	color: var(--thm-black);
	line-height: 1.5em;
	margin-top: 9px;
}

.about-four__feature__icon {
	display: flex;
	align-items: center;
}

.about-four__feature__icon i {
	font-size: 26px;
	color: var(--thm-base);
	margin-right: 12px;
}

.about-four__feature__content {
	display: flex;
	align-items: center;
}

.about-four__feature__title {
	margin: 0;
	font-size: 18px;
	font-weight: bold;
	color: var(--thm-black);
	line-height: 1.66667em;
}

.about-four__feature__text {
	margin: 0;
	margin-top: 8px;
}

@media (min-width: 768px) {
	[class*=col-]:last-child .about-four__feature {
		margin-left: -20px;
	}
}

.about-four__btns {
	margin-top: 40px;
}

.about-four__image {
	display: inline-block;
	position: relative;
}

.about-four__image::before {
	content: '';
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	bottom: 20px;
	background-color: var(--thm-base);
	transform: skew(-3deg);
	z-index: 10;
}

.about-four__image img {
	position: relative;
	max-width: 100%;
	z-index: 11;
}

.about-four__image__caption {
	position: absolute;
	bottom: 88px;
	right: -38px;
	display: none;
	align-items: center;
	background-color: var(--thm-base);
	padding-left: 32px;
	padding-right: 33px;
	padding-top: 23px;
	padding-bottom: 24px;
	z-index: 12;
}

@media (min-width: 768px) {
	.about-four__image__caption {
		display: flex;
	}
}

.about-four__image__caption::after {
	content: '';
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #c1180b;
	width: 38px;
	height: 41px;
	clip-path: polygon(0 0, 0 100%, 100% 0);
}

.about-four__image__caption__year {
	font-size: 45px;
	font-weight: bold;
	color: #fff;
	margin: 0;
	margin-right: 15px;
}

.about-four__image__caption__year i {
	font-style: normal;
	font-size: 30px;
	position: relative;
	top: -15px;
}

.about-four__image__caption__text {
	font-size: 16px;
	color: #fff;
	line-height: 1.25em;
	font-weight: 500;
	margin: 0;
	max-width: 85px;
	margin-top: -1px;
}

.about-four__image__floated {
	text-transform: uppercase;
	color: #ffffff;
	font-size: 130px;
	font-weight: 900;
	letter-spacing: 0em;
	position: absolute;
	left: calc(100% - 54px);
	top: calc(50% - 4px);
	transform: rotate(90deg);
	transform-origin: top center;
	display: none;
}

@media (min-width: 992px) {
	.about-four__image__floated {
		display: block;
	}
}

.about-four__image__bg {
	width: 10000px;
	height: calc(100% + 527px);
	background-color: var(--thm-gray);
	position: absolute;
	top: -120px;
	left: 325px;
}

.about-four__image__shape {
	width: 194px;
	height: 189px;
	background-image: url(../images/shapes/about-4-dot-1.png);
	background-repeat: no-repeat;
	background-position: top center;
	position: absolute;
	top: -60px;
	right: -113px;
}

.about-five {
	position: relative;
}

.about-five__shape-1 {
	position: absolute;
	top: -90px;
	left: 310px;
	width: 97px;
	height: 133px;
	background-image: url(../images/shapes/about-5-s-1.png);
	background-repeat: no-repeat;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.about-five__shape-2 {
	position: absolute;
	bottom: 140px;
	right: 125px;
	width: 158px;
	height: 137px;
	background-image: url(../images/shapes/about-5-s-2.png);
	background-repeat: no-repeat;
	animation-name: bubbleMover;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 8s;
}

.about-five__shape-3 {
	position: absolute;
	top: -208px;
	right: 0;
	width: 638px;
	height: 585px;
	background-image: url(../images/shapes/about-5-bg-1-1.png);
	background-repeat: no-repeat;
	display: none;
}

@media (min-width: 992px) {
	.about-five__shape-3 {
		display: block;
	}
}

.about-five__image {
	position: relative;
	display: inline-block;
}

.about-five__image::before {
	content: '';
	border: 2px solid var(--thm-base);
	position: absolute;
	top: 30px;
	left: 30px;
	right: 30px;
	bottom: 30px;
	z-index: 11;
}

.about-five__image img {
	max-width: 100%;
	position: relative;
	z-index: 10;
}

.about-five__image__caption {
	position: absolute;
	bottom: -45px;
	left: -45px;
	width: 275px;
	height: 275px;
	padding-left: 40px;
	display: none;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 20px;
}

@media (min-width: 992px) {
	.about-five__image__caption {
		display: flex;
	}
}

.about-five__image__caption::after {
	content: '';
	clip-path: polygon(0 0, 0% 100%, 100% 100%);
	background-color: var(--thm-black);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 12;
}

.about-five__image__caption__shape-1 {
	width: 90px;
	height: 90px;
	background-color: var(--thm-black2);
	position: absolute;
	top: -43px;
	left: 18px;
	clip-path: polygon(100% 100%, 0 0, 0 100%);
	transform: rotate(45deg);
}

.about-five__image__caption__shape-2 {
	width: 90px;
	height: 90px;
	background-color: var(--thm-black2);
	position: absolute;
	bottom: 18px;
	right: -43px;
	clip-path: polygon(100% 100%, 0 0, 0 100%);
	transform: rotate(-45deg);
}

.about-five__image__caption__shape-3 {
	position: absolute;
	bottom: 0;
	z-index: 14;
	background-color: var(--thm-base);
	width: 20px;
	height: 200px;
	left: 0;
}

.about-five__image__caption__shape-3::before {
	content: '';
	width: 20px;
	height: 20px;
	background-color: var(--thm-base);
	position: absolute;
	bottom: 100%;
	left: 0;
	clip-path: polygon(100% 100%, 0 0, 0 100%);
}

.about-five__image__title {
	margin: 0;
	color: #fff;
	font-size: 60px;
	font-weight: 800;
	line-height: 1;
	position: relative;
	z-index: 13;
}

.about-five__image__title i {
	font-size: 32px;
	font-style: normal;
	position: relative;
	top: -2px;
}

.about-five__image__text {
	margin: 0;
	font-size: 18px;
	position: relative;
	z-index: 13;
	color: #fff;
}

.about-five__image__floated {
	text-transform: uppercase;
	color: var(--thm-gray);
	font-size: 120px;
	font-weight: 200;
	letter-spacing: .32em;
	position: absolute;
	right: calc(100% - 333px);
	top: calc(50% - -18px);
	transform: rotate(90deg);
	transform-origin: top center;
	display: none;
}

@media (min-width: 992px) {
	.about-five__image__floated {
		display: block;
	}
}

.about-five__subtitle {
	margin: 0;
	text-transform: uppercase;
	font-size: 18px;
	color: var(--thm-base);
	font-weight: bold;
	margin-bottom: 10px;
}

.about-five__text {
	margin: 0;
	margin-bottom: 40px;
}

.about-five .team-progress__bar {
	height: 8px;
	border-radius: 4px;
}

.about-five .team-progress__title {
	font-size: 20px;
	margin-bottom: 13px;
}

.about-five .team-progress__bar__text {
	font-size: 16px;
	padding-bottom: 15px;
}

.about-five .about-two__list {
	margin: 0;
	margin-top: 41px;
}

@media (min-width: 768px) {
	.about-five .about-two__list {
		margin-right: -42px;
	}
}

.about-five__video {
	display: inline-block;
	position: relative;
}

@media (min-width: 768px) {
	.about-five__video {
		margin-left: auto;
		display: block;
		margin-top: 61px;
		width: 217px;
	}
}

.about-five__video img {
	width: 100%;
}

.about-five__video__btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.about-five__video__btn i {
	background-color: var(--thm-base);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 12px;
	width: 47px;
	height: 47px;
	border-radius: 50%;
	transition: all 500ms ease;
}

.about-five__video__btn:hover i {
	background-color: #fff;
	color: var(--thm-black);
}

.about-five__video__btn .ripple,
.about-five__video__btn .ripple:before,
.about-five__video__btn .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 47px;
	height: 47px;
	border-radius: 50%;
	-ms-border-radius: 50%;
	transform: translate(-50%, -50%);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.about-five__video__btn .ripple:before {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
	content: "";
	position: absolute;
}

.about-five__video__btn .ripple:after {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
	content: "";
	position: absolute;
}

.about-six {
	background-image: url(../images/shapes/about-6-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: left -20%;
	position: relative;
}

.about-six::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/about-6-bg-c-1.png);
	background-position: right -15%;
	background-repeat: no-repeat;
}

.about-six .container {
	position: relative;
}

.about-six__image {
	position: relative;
	display: inline-block;
}

.about-six__image::before {
	content: '';
	position: absolute;
	top: 20px;
	left: -20px;
	right: 0;
	bottom: 30px;
	background-color: var(--thm-base);
}

.about-six__image img {
	max-width: 100%;
	position: relative;
}

.about-six__image__caption {
	width: 180px;
	height: 175px;
	background-color: #fff;
	border-radius: 50%;
	border-bottom-right-radius: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
	top: 105px;
	left: 20px;
}

@media (min-width: 992px) {
	.about-six__image__caption {
		left: -130px;
	}
}

.about-six__image__caption::before {
	content: '';
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	border: 2px solid var(--thm-black);
	border-radius: 50%;
	border-bottom-right-radius: 0;
}

.about-six__image__caption__year {
	position: relative;
	margin: 0;
	line-height: 1;
	font-size: 45px;
	font-weight: bold;
	color: var(--thm-base);
}

.about-six__image__caption__text {
	position: relative;
	margin: 0;
	font-size: 18px;
	line-height: 1.44444em;
	color: var(--thm-black);
	font-weight: 500;
	max-width: 87px;
	margin-left: auto;
	margin-right: auto;
}

.about-six__content {
	position: relative;
}

.about-six__list {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;
	margin-bottom: 36px;
}

@media (min-width: 576px) {
	.about-six__list {
		grid-template-columns: repeat(2, 1fr);
	}
}

.about-six__list li {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid var(--thm-border-color);
	font-weight: bold;
	color: var(--thm-black);
	font-size: 18px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.about-six__list li i {
	color: var(--thm-base);
	font-size: 18px;
	margin-right: 15px;
}

.about-six__subtitle {
	margin: 0;
	text-transform: uppercase;
	font-size: 18px;
	color: var(--thm-base);
	font-weight: bold;
}

.about-six__text {
	margin: 0;
	margin-top: 34px;
	margin-bottom: 23px;
}

.about-six__author {
	display: flex;
	align-items: center;
}

.about-six__author__image img {
	border-radius: 50%;
	border: 3px solid #fff;
	box-shadow: 0 0 0 2px var(--thm-base);
}

.about-six__author__name {
	color: var(--thm-black);
	font-family: var(--special-font);
	font-size: 24px;
	margin-left: 10px;
}

.work-process {
	counter-reset: workNumber;
	position: relative;
}

.work-process__shape-1 {
	position: absolute;
	top: 0px;
	left: 190px;
	width: 97px;
	height: 133px;
	background-image: url(../images/shapes/about-5-s-1.png);
	background-repeat: no-repeat;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.work-process__shape-2 {
	position: absolute;
	bottom: 80px;
	right: 180px;
	width: 158px;
	height: 137px;
	background-image: url(../images/shapes/about-5-s-2.png);
	background-repeat: no-repeat;
	animation-name: shapeMover;
	animation-duration: 4s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.work-process__shape-3 {
	position: absolute;
	top: 5px;
	right: 235px;
	width: 83px;
	height: 69px;
	background-image: url(../images/shapes/service-h-2.png);
	background-repeat: no-repeat;
	animation-name: bubbleMover;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 8s;
}

.work-process .container {
	position: relative;
}

.work-process__item {
	counter-increment: workNumber;
	text-align: center;
}

.work-process__icon {
	width: 150px;
	height: 150px;
	background-color: #fff;
	border: 1px solid var(--thm-border-color);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	color: var(--thm-base);
	position: relative;
	transition: all 500ms ease;
}

.work-process__icon::after {
	content: counters(workNumber, ".", decimal-leading-zero);
	width: 40px;
	height: 40px;
	background-color: var(--thm-base);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	position: absolute;
	top: 0px;
	left: 0px;
	font-size: 14px;
	font-weight: bold;
	transition: all 0.5s ease;
}

.work-process__icon i {
	font-size: 61px;
}

.work-process__item:hover .work-process__icon {
	background-color: var(--thm-base);
	color: #fff;
}

.work-process__item:hover .work-process__icon::after {
	background-color: var(--thm-black);
	color: #fff;
}

[class*=col-]:nth-child(even) .work-process__icon::after {
	bottom: 0;
	right: 0;
	top: auto;
	left: auto;
}

@media (min-width: 992px) {
	.work-process .row {
		background-image: url(../images/shapes/work-process-1-1.png);
		background-repeat: no-repeat;
		background-position: center 10%;
	}
}

@media (min-width: 992px) {
	[class*=col-]:nth-child(2) .work-process__item {
		margin-top: 100px;
	}
}

@media (min-width: 992px) {
	[class*=col-]:nth-child(3) .work-process__item {
		margin-top: 50px;
	}
}

.work-process__title {
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	color: var(--thm-black);
	margin-top: 26px;
	margin-bottom: 17px;
}

.work-process__title a {
	color: inherit;
	transition: all 500ms ease;
}

.work-process__text {
	margin: 0;
	max-width: 202px;
	margin-left: auto;
	margin-right: auto;
}

.benefit-two {
	position: relative;
	background-image: url(../images/backgrounds/bg-course.png);
	background-size: cover;
	background-position: left top;
	padding-top: 120px;


}

@media (max-width: 600px) {
	.benefit-two {

		background-position: right;
		padding-top: 0px;
		margin-top: -40px;

	}
}


@media (min-width: 1200px) {
	.benefit-two {
		padding-top: 0;
	}
}

.benefit-two::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* background-image: url(../images/shapes/benefit-2-circle.png); */
	background-position: top right;
	background-repeat: no-repeat;
}

.benefit-two .container {
	position: relative;
}

.benefit-two__image img {
	max-width: 100%;
	position: relative;
}

@media (min-width: 992px) {
	.benefit-two__image img {
		max-width: none;
		float: right;
		top: 120px;
	}
}

.benefit-two__content {
	padding-top: 60px;
}

@media (min-width: 992px) {
	.benefit-two__content {
		padding-top: 120px;
	}
}

@media (min-width: 1200px) {
	.benefit-two__content {
		padding-left: 90px;
	}
}

.benefit-two__tab .tabs-content .tab {
	display: none;
}

.benefit-two__tab .tabs-content .active-tab {
	display: block;
}

.benefit-two__tab__title {
	display: flex;
	align-items: center;
	/* flex-wrap: wrap; */
	margin: -5px;
	margin-bottom: 35px;
	white-space: nowrap;
}

@media (max-width: 600px) {
	.benefit-two__tab__title {
		/* display: flex;
		align-items: center; */
		flex-wrap: wrap;
		white-space: unset;
		/* margin: -5px;
		margin-bottom: 35px;
		white-space: nowrap; */
	}
}



.benefit-two__tab__title li {
	padding: 5px;
}

.benefit-two__tab__title li span {
	background-color: #fff;
	display: block;
	color: var(--thm-black);
	font-size: 12px;
	font-weight: 600;
	padding: 9px 14px;
	transition: all 500ms ease;
	cursor: pointer;
}

@media (max-width: 1400px) {

	.benefit-two__tab__title li span {

		font-size: 10px;
		font-weight: 600;
		padding: 9px 14px;

	}

}

.benefit-two__tab__title li.active-btn span,
.benefit-two__tab__title li:hover span {
	background-color: var(--thm-base);
	color: #fff;
}

.benefit-two__tab__content__inner p {
	margin: 0;
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.benefit-two__tab__content__box {
		display: flex;
	}
}

.benefit-two .about-two__list {
	margin: 0;
	margin-top: 30px;
}

@media (min-width: 768px) {
	.benefit-two .about-two__list {
		margin-top: 15px;
		margin-left: 40px;
	}
}

.benefit-two__fact {
	margin-top: 50px;
	position: relative;
}

@media (min-width: 768px) {
	.benefit-two__fact {
		display: flex;
		align-items: center;
	}
}

@media (min-width: 1200px) {
	.benefit-two__fact {
		margin-top: 0;
		top: 155px;
	}
}

.benefit-two__fact::before {
	content: '';
	width: 100000px;
	border-bottom: 1px solid var(--thm-gray);
	height: 1px;
	position: absolute;
	bottom: -35px;
	left: 0;
	display: none;
}

@media (min-width: 1200px) {
	.benefit-two__fact::before {
		display: block;
	}
}

.benefit-two__fact__icon {
	font-size: 60px;
	position: relative;
	color: var(--thm-base);
	padding-left: 20px;
	line-height: 1;
	margin-right: 30px;
}

.benefit-two__fact__icon::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 47px;
	height: 47px;
	background-color: #fff;
	border-radius: 50%;
}

@media (min-width: 1200px) {
	.benefit-two__fact__icon::before {
		background-color: var(--thm-gray);
	}
}

.benefit-two__fact__icon i {
	position: relative;
}

@media (min-width: 992px) {
	.benefit-two__fact__content {
		display: flex;
		align-items: flex-end;
	}
}

.benefit-two__fact__title {
	margin: 0;
	color: var(--thm-black);
	font-size: 30px;
	font-weight: bold;
	line-height: 1;
	margin-top: 20px;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.benefit-two__fact__title {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@media (min-width: 992px) {
	.benefit-two__fact__title {
		font-size: 40px;
		margin-right: 17px;
	}
}

.benefit-two__fact__text {
	margin: 0;
	font-size: 18px;
}

.emi-calculation {
	position: relative;
	margin-bottom: 30px;
	background-color: #f2ede9;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	background-color: #fff;
}



.emi-calculation-content {
	padding-left: 50px;
	padding-top: 25px;
	padding-right: 50px;
	padding-bottom: 35px;
}

@media (max-width: 425px) {
	.emi-calculation-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.emi-calculation-content p {
	margin: 0;
	text-align: center;
	font-size: 16px;
	align-items: center;
}

@media (max-width: 1199px) {
	.emi-calculation-content p {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	.emi-calculation-content p {
		font-size: 16px;
	}
}

@media (max-width: 425px) {
	.emi-calculation-content p {
		display: block;
		text-align: center;
	}
}

.emi-calculation-content p span {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
	display: inline-block;
}

@media (max-width: 767px) {
	.emi-calculation-content p span {
		font-size: 14px;
	}
}

@media (max-width: 490px) {
	.emi-calculation-content p span {
		max-width: 100px;
	}
}

.emi-calculation-content p i {
	font-style: normal;
}

.emi-calculation-content p b {
	font-weight: 600;
	font-size: 35px;
	color: var(--thm-black);
	line-height: 1;
	display: block;
}

@media (max-width: 425px) {
	.emi-calculation-content p b {
		margin-top: 10px;
	}
}

.loan-calculation {

	position: relative;
	z-index: 10;
	margin-left: 0px;
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

#loan-chart {
	min-height: 230px;
}

@media(max-width: 499px) {
	#loan-chart {
		min-height: 170px;
	}
}

@media (max-width: 1199px) {
	.loan-calculation {
		margin-left: 0;
	}
}

.loan-calculation.loan-eligibility {
	background-color: var(--thm-gray);
	box-shadow: none;
}

.loan-calculation-content {
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 30px;
	padding-top: 50px;
}

@media (max-width: 425px) {
	.loan-calculation-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.loan-calculation-content p {
	margin: 0;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	align-items: center;
}

@media (max-width: 1199px) {
	.loan-calculation-content p {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	.loan-calculation-content p {
		font-size: 16px;
	}
}

@media (max-width: 425px) {
	.loan-calculation-content p {
		display: block;
		text-align: center;
	}
}

.loan-calculation-content p span {
	line-height: 1;
	font-size: 16px;
	font-weight: 500;
}

@media (max-width: 767px) {
	.loan-calculation-content p span {
		font-size: 14px;
	}
}

@media (max-width: 490px) {
	.loan-calculation-content p span {
		max-width: 100px;
	}
}

.loan-calculation-content p i {
	font-style: normal;
}

.loan-calculation-content p b {
	font-weight: 400;
	color: var(--thm-black);
	line-height: 1;
	display: block;
	font-size: 20px;
}

@media (max-width: 425px) {
	.loan-calculation-content p b {
		margin-top: 10px;
	}
}

.loan-calculation-content .loan-eligibility-inner {
	text-align: left;
	font-size: 30px;
	color: var(--thm-black);
}

.loan-calculation-content .loan-eligibility-inner h4 {
	font-weight: 700;
}

.loan-calculation-content .loan-eligibility-inner #loan-eligibility-emi {
	font-size: 20px;
}

.loan-calculation-content p+p,
.loan-calculation-content .loan-eligibility-inner+.loan-eligibility-inner {
	padding-top: 25px;
	margin-top: 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.loan-calculation .thm-btn {
	width: 100%;
	margin-top: 35px;
	padding-top: 17.5px;
	padding-bottom: 17.5px;
}

.loan-calculation .thm-btn.scroll-to-chart {
	background-color: var(--thm-gray);
	color: var(--thm-black);
}

.loan-calculation .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.loan-calculation .noUi-horizontal {
	height: 7px;
	border-radius: 4px;
	background-color: #eff2f6;
	border: none;
	box-shadow: none;
}

.loan-calculation .noUi-horizontal .noUi-handle {
	width: 20px;
	height: 20px;
	border: 4px solid #fff;
	background: var(--thm-base);
	border-radius: 50%;
	outline: none !important;
	box-shadow: none;
}

.loan-calculation .noUi-horizontal .noUi-handle::before,
.loan-calculation .noUi-horizontal .noUi-handle::after {
	display: none;
}

.loan-calculation .noUi-tooltip {
	border: none;
	padding: 0;
	color: var(--thm-color);
	font-size: 15px;
	background-color: none;
	box-shadow: none;
}

.loan-calculation .noUi-horizontal .noUi-tooltip {
	bottom: 185%;
	font-weight: 500;
}

.loan-calculation .noUi-connect {
	background-color: var(--thm-base);
	border-radius: 4px;
}

/*--------------------------------------------------------------
# fact
--------------------------------------------------------------*/
.fact-one {
	background-image: url(../images/shapes/fact-bg-1-1.png);
	background-color: var(--thm-black);
	background-position: center center;
}

.fact-one__item {
	text-align: center;
	margin-top: -7px;
}

.fact-one__count {
	font-size: 60px;
	font-weight: bold;
	color: #fff;
	line-height: 1em;
	white-space: none;
}

.fact-one__count .count-box {
	display: inline-flex;
}

.fact-one__title {
	font-size: 18px;
	margin: 0;
	color: #fff;
	display: inline-block;
	border: 1px solid var(--thm-base);
	padding-top: 9px;
	padding-bottom: 12px;
	padding-left: 29px;
	padding-right: 29px;
	margin-top: 23px;
}

/*--------------------------------------------------------------
# Testimonial
--------------------------------------------------------------*/
.testimonial-card {
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	position: relative;
	padding-left: 33px;
	padding-right: 33px;
	padding-top: 50px;
	padding-bottom: 44px;
	margin-right: 33px;
}

@media (min-width: 768px) {
	.testimonial-card {
		min-height: 550px;
	}
}

.testimonial-card::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: 0 0 0 0px red;
	transition: all 500ms ease;
}

.testimonial-card:hover::before {
	box-shadow: 0 0 0 2px red;
}

.testimonial-card__info {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

@media (min-width: 768px) {
	.testimonial-card__info {
		flex-wrap: nowrap;
	}
}

.testimonial-card__stars {
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}

.testimonial-card__stars i {
	color: var(--thm-base);
	font-size: 12px;
}

.testimonial-card__stars i+i {
	margin-left: 2px;
}

.testimonial-card__name {
	margin: 0;
	font-size: 20px;
	font-weight: bold;
}

.testimonial-card__designation {
	margin: 0;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .2em;
}

.testimonial-card__image {
	position: relative;
	width: 100px;
	margin-right: 0px;
	margin-bottom: 20px;
}

@media (min-width: 321px) {
	.testimonial-card__image {
		margin-right: 30px;
	}
}

@media (min-width: 401px) {
	.testimonial-card__image {
		margin-bottom: 0;
		margin-right: 40px;
	}
}

.testimonial-card__image::before {
	content: '';
	position: absolute;
	top: -10px;
	right: -10px;
	width: 100%;
	height: 100%;
	border: 2px dashed var(--thm-base);
}

.testimonial-card__image img {
	position: relative;
}

.testimonial-card__text p {
	margin: 0;
}

.testimonial-card__line {
	width: 100%;
	height: 42px;
	background-image: url(../images/shapes/testi-line-1-1.png);
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center;
	display: block;
	transition: 500ms ease;
	margin-top: 30px;
	margin-bottom: 20px;
}

.testimonial-card:hover .testimonial-card__line {
	background-image: url(../images/shapes/testi-line-1-1-h.png);
}

.testimonials-one {
	background-image: url(../images/shapes/testi-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: center left;
}

.testimonials-two {
	background-repeat: no-repeat;
	background-position: center left;
	background-image: url(../images/shapes/testi-bg-2-1.png);
	background-color: var(--thm-gray);
}

.testimonials-two .block-title {
	position: relative;
	z-index: 10;
}

.testimonials-two__btn {
	position: relative;
	z-index: 10;
	align-items: center;
	display: none;
}

@media (min-width: 1200px) {
	.testimonials-two__btn {
		display: flex;
	}
}

.testimonials-two__btn__left,
.testimonials-two__btn__right {
	width: 51px;
	height: 51px;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color: var(--thm-black);
	opacity: 0.5;
	transition: all 0.5s ease;
	border: none;
	outline: none;
}

.testimonials-two__btn__left:hover,
.testimonials-two__btn__right:hover {
	opacity: 1;
}

.testimonials-two__btn__right {
	margin-left: 10px;
}

.testimonials-two__carousel .owl-stage-outer {
	overflow: visible;
}

.testimonials-two__carousel .owl-item {
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease;
}

.testimonials-two__carousel .owl-item.active {
	opacity: 1;
	visibility: visible;
}

@media (min-width: 1200px) {
	.testimonials-two__carousel .owl-item.active+.active+.owl-item {
		opacity: 1;
		visibility: visible;
	}
}

@media (min-width: 1200px) {
	.testimonials-two__carousel .owl-dots {
		display: none;
	}
}

.testimonials-three {
	position: relative;
}

.testimonials-three__shape {
	position: absolute;
	bottom: 0;
	left: -50px;
	background-image: url(../images/shapes/testi-3-map-1-1.png);
	width: 429px;
	height: 410px;
	z-index: 10;
}

.testimonials-three .block-title {
	margin-bottom: 60px;
}

.testimonials-three__carousel {
	background-color: var(--thm-base);
	position: relative;
	padding: 40px;
	margin-bottom: 50px;
	position: relative;
	z-index: 5;
}

.testimonials-three__carousel::before {
	content: '';
	position: absolute;
	top: 0;
	right: 100%;
	width: 100000px;
	background-color: var(--thm-base);
	height: 100%;
	display: none;
}

@media (min-width: 1200px) {
	.testimonials-three__carousel::before {
		display: block;
	}
}

@media (min-width: 992px) {
	.testimonials-three__carousel {
		margin-bottom: 0;
	}
}

@media (min-width: 1200px) {
	.testimonials-three__carousel {
		padding: 0;
		padding-top: 60px;
		padding-bottom: 60px;
		margin-right: -125px;
		padding-right: 125px;
	}
}

.testimonials-three__carousel__line {
	position: absolute;
	top: 0;
	left: 0;
	right: 33px;
	bottom: 0;
	background-image: url(../images/shapes/testi-line-2-1.png);
	background-repeat: no-repeat;
	background-position: right top;
	z-index: 10;
	display: none;
}

@media (min-width: 1200px) {
	.testimonials-three__carousel__line {
		display: block;
	}
}

.testimonials-three__carousel .thm-owl__carousel {
	position: relative;
	z-index: 11;
}

.testimonials-three__carousel .owl-dot-style-one .owl-nav.disabled+.owl-dots {
	margin-top: 40px;
}

@media (min-width: 1200px) {
	.testimonials-three__carousel .owl-dot-style-one .owl-nav.disabled+.owl-dots {
		margin-top: 0;
	}
}

@media (min-width: 1200px) {
	.testimonials-three__carousel .owl-dots {
		position: absolute;
		bottom: 0px;
		right: 15px;
	}
}

.testimonials-three__carousel .owl-dots .owl-dot span {
	background-color: #fff;
	opacity: 0.3;
}

.testimonials-three__carousel .owl-dots .owl-dot.active span,
.testimonials-three__carousel .owl-dots .owl-dot:hover span {
	background-color: #fff;
	opacity: 1;
}

.testimonials-three__image {
	position: relative;
	z-index: 2;
}

.testimonials-three__image img {
	max-width: 100%;
}

@media (min-width: 992px) {
	.testimonials-three__image img {
		max-width: none;
	}
}

@media (min-width: 1200px) {
	.testimonials-three__image img {
		margin-left: 45px;
	}
}

.testimonials-three .testimonial-card__stars i,
.testimonials-three .testimonial-card-two__text,
.testimonials-three .testimonial-card__name,
.testimonials-three .testimonial-card-two__icon,
.testimonials-three .testimonial-card__designation {
	color: #fff;
}

.testimonials-three .testimonial-card__image::before {
	border-color: var(--thm-black);
}

.testimonial-card-two {
	position: relative;
}

.testimonial-card-two__content {
	margin-bottom: 35px;
}

@media (min-width: 992px) {
	.testimonial-card-two__content {
		display: flex;
	}
}

@media (min-width: 1200px) {
	.testimonial-card-two__content {
		margin-bottom: 55px;
	}
}

.testimonial-card-two__icon {
	font-size: 100px;
	line-height: 1;
}

@media (min-width: 992px) {
	.testimonial-card-two__icon {
		position: relative;
		top: -4px;
		margin-right: 30px;
	}
}

@media (min-width: 1200px) {
	.testimonial-card-two__icon {
		margin-right: 46px;
	}
}

.testimonial-card-two__text {
	font-weight: 500;
}

@media (min-width: 1200px) {
	.testimonial-card-two__text {
		max-width: 423px;
	}
}

/*--------------------------------------------------------------
# Video
--------------------------------------------------------------*/
.video-one {
	background-color: var(--thm-black);
	background-attachment: fixed;
	position: relative;
	background-size: cover;
	background-position: center center;
}

.video-one::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--thm-black);
	opacity: 0.6;
}

.video-one__shape {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-image: url(../images/shapes/video-bg-1-2.png);
	background-repeat: no-repeat;
	display: none;
}

@media (min-width: 992px) {
	.video-one__shape {
		display: block;
	}
}

.video-one--home .video-one__shape {
	background-image: url(../images/shapes/video-bg-1-1.png);
}

.video-one .container {
	position: relative;
	z-index: 10;
}

.video-one__btn {
	width: 96px;
	height: 96px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	position: relative;
}

.video-one__btn i {
	background-color: var(--thm-base);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 12px;
	width: 62px;
	height: 62px;
	border-radius: 50%;
	transition: all 500ms ease;
}

.video-one__btn:hover i {
	background-color: #fff;
	color: var(--thm-black);
}

.video-one__btn .ripple,
.video-one__btn .ripple:before,
.video-one__btn .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 96px;
	height: 96px;
	border-radius: 50%;
	-ms-border-radius: 50%;
	transform: translate(-50%, -50%);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.video-one__btn .ripple:before {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
	content: "";
	position: absolute;
}

.video-one__btn .ripple:after {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
	content: "";
	position: absolute;
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.video-one__title {
	margin: 0;
	color: #fff;
	font-size: 40px;
	font-weight: 800;
	margin-top: 40px;
}

@media (min-width: 768px) {
	.video-one__title {
		font-size: 45px;
	}
}

.video-one__list {
	margin: 0;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 768px) {
	.video-one__list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 992px) {
	.video-one__list {
		padding-left: 53px;
	}
}

.video-one__list li {
	background-color: rgba(var(--thm-black2-rgb), 0.4);
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 14px;
	padding-bottom: 17px;
}

.video-one__list li:nth-child(2) {
	background-color: rgba(255, 255, 255, 0.1);
}

.video-one__list li:nth-child(3n) {
	background-color: rgba(255, 255, 255, 0.1);
}

.video-one__list li>i {
	font-size: 19px;
	color: var(--thm-base);
	margin-right: 20px;
}

/*--------------------------------------------------------------
# Loan Calculator
--------------------------------------------------------------*/
.loan-calculator {
	position: relative;
	z-index: 10;
}

.loan-calculator--has-bg {
	background-image: linear-gradient(0deg, #ffffff 0%, var(--thm-gray) 100%);
}

.loan-calculator--has-bg::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/shapes/faq-form-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top center;
}

.loan-calculator--has-bg .container {
	position: relative;
}

.loan-calculator__top__text {
	margin-bottom: 50px;
}

.loan-calculator__form {
	padding: 40px;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	margin-top: 40px;
	background-color: #fff;
}

@media (min-width: 992px) {
	.loan-calculator__form {
		margin-top: 0;
		padding-top: 7px;
		padding-bottom: 8px;
	}
}

@media (min-width: 1200px) {
	.loan-calculator__form {
		padding-top: 55px;
		padding-bottom: 53px;
		padding-left: 80px;
		padding-right: 80px;
		margin-right: -43px;
	}
}

.loan-calculator__form__terms {
	font-size: 14px;
	line-height: 2.14286em;
	font-weight: 300;
	margin-top: 21px;
}

.loan-calculator__form .thm-btn {
	margin-top: 40px;
}

.loan-calculator__form p {
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	font-size: 18px;
	align-items: center;
}

.loan-calculator__form p:first-child {
	margin-top: 40px;
}

.loan-calculator__form p span {
	line-height: 1;
}

.loan-calculator__form p i {
	font-style: normal;
}

.loan-calculator__form p b {
	font-weight: 600;
	color: var(--thm-black);
	line-height: 1;
}

.loan-calculator__form p+p {
	padding-top: 30px;
	margin-top: 30px;
	border-top: 1px solid var(--thm-border-color);
}

.loan-calculator__form .noUi-horizontal {
	height: 7px;
	border-radius: 3.5px;
	background-color: var(--thm-gray);
	border: none;
	box-shadow: none;
}

.loan-calculator__form .noUi-horizontal .noUi-handle {
	width: 20px;
	height: 20px;
	border: 4px solid var(--thm-base);
	background: #fff;
	border-radius: 50%;
	outline: none !important;
	box-shadow: none;
}

.loan-calculator__form .noUi-horizontal .noUi-handle::before,
.loan-calculator__form .noUi-horizontal .noUi-handle::after {
	display: none;
}

.loan-calculator__form .noUi-tooltip {
	border: none;
	padding: 0;
	color: var(--thm-color);
	font-size: 15px;
	background-color: none;
	box-shadow: none;
}

.loan-calculator__form .noUi-horizontal .noUi-tooltip {
	top: 185%;
}

.loan-calculator__form .noUi-connect {
	background-color: var(--thm-base);
	border-radius: 0px;
}

.loan-calculator__form .input-box {
	margin-bottom: 50px;
}

.loan-calculator__form .input-box__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;
	color: var(--thm-color);
	margin-bottom: 12px;
}

.loan-calculator__image {
	position: relative;
}

@media (max-width: 991px) {
	.loan-calculator__image {
		text-align: center;
	}
}

.loan-calculator__image img {
	max-width: 100%;
}

@media (min-width: 1200px) {
	.loan-calculator__image img {
		margin-left: 43px;
	}
}

.loan-calculator__image__caption {
	width: 398px;
	height: 295px;
	background-image: url(../images/shapes/calculator-form-bubble-bg-1-1.png);
	background-repeat: no-repeat;
	align-items: flex-start;
	justify-content: center;
	position: absolute;
	z-index: 10;
	bottom: 80px;
	right: -120px;
	display: none;
}

@media (min-width: 1200px) {
	.loan-calculator__image__caption {
		display: flex;
	}
}

.loan-calculator__image__caption__inner {
	background-color: var(--thm-base);
	border: 4px solid var(--thm-black);
	width: 260px;
	height: 130px;
	margin-top: 59px;
	padding-left: 30px;
	padding-top: 20px;
}

.loan-calculator__image__title {
	margin: 0;
	color: #fff;
	font-size: 55px;
	font-weight: 600;
	line-height: 1;
}

.loan-calculator__image__title span {
	font-size: 35px;
	position: relative;
	top: -20px;
	right: 3px;
}

.loan-calculator__image__text {
	font-size: 20px;
	color: #fff;
	line-height: 1;
	font-weight: 400;
	margin-top: 5px;
}

@media(max-width: 576px) {
	.loan-calculator__box {
		padding: 0px 15px;
	}
}

.slider-box {
	margin-bottom: 30px;
}

.main-heading.block-title {
	margin-bottom: 40px;
}

.main-heading p {
	line-height: 1.66;
	margin: 0;
}

.main-heading h3 {
	font-size: 34px;
}

.single-loan-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	flex-wrap: wrap;
}

.main-slide h4 {
	font-size: 22px;
}

.main-slide .input-control {
	border-radius: 0;
	max-width: 200px;
	padding: 13px 15px;
	outline: 0;
	background-color: var(--thm-gray);
	border: none;
	color: var(--thm-black);
}

@media(max-width: 576px) {
	.single-loan-header {
		display: block;
	}

	.main-slide h4 {
		margin-bottom: 20px;
	}

	.main-slide input {
		max-width: none;
		width: 100%;
		margin-bottom: 10px;
	}
}

.slider-box .ui-widget-content {
	border: none !important;
	background: #eff2f6 !important;
	height: 7px;
}

.main-slide .ui-slider .ui-slider-range {
	background: var(--thm-base);
	border-radius: 3px;
}

.main-slide .ui-slider-horizontal .ui-slider-handle {
	top: -6px !important;
	margin-left: -5px !important;
}

.main-slide .ui-slider .ui-slider-handle {
	width: 20px;
	height: 20px;
	border: 4px solid var(--thm-base);
	background: #fff;
	border-radius: 50%;
	outline: none !important;
	box-shadow: none;
}

.loan-counter-value {
	font-weight: 600;
	font-size: 18px;
	color: #6e6d6e;
	margin-bottom: 40px;
}

.loan-counter-value h6 {
	margin-top: 14px;
	display: inline-block;
	font-weight: 400;
	font-size: 18px;
	color: var(--thm-color);
}

.loan-counter-value {
	font-weight: 400;
	font-size: 18px;
	color: #6e6d6e;
	margin-bottom: 30px;
}

.total-loan-form {
	margin-bottom: 40px;
}

.total-loan-form-emi {
	margin-bottom: 90px;
}

.total-loan-form-calculation {
	text-align: center;
}

@media (max-width: 767px) {
	.total-loan-form {
		padding: 20px;
	}
}

.highcharts-exporting-group,
.highcharts-credits {
	display: none;
}

/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/
.slider-one {
	position: relative;
}

.slider-one .thm-owl__carousel {
	width: 100%;
}

.slider-one__item {
	width: 100%;
	padding-top: 110px;
	padding-bottom: 120px;
	position: relative;
	background-color: var(--thm-black);
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width:600px) {
	.slider-one__item {
		text-align: center;
	}
}

@media (min-width: 992px) {
	.slider-one__item {
		padding-top: 0;
		padding-bottom: 0;
		min-height: 766px;
	}
}

.slider-one__lines {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.1;
	display: flex;
	z-index: 11;
}

.slider-one__lines span {
	width: 20%;
	height: 100%;
	display: block;
	border-right: 1px solid #ffffff;
}

.slider-one__image {
	position: absolute;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: center right;
	background-size: cover;
	background-repeat: no-repeat;
	transform: scale(1);
}

.slider-one__image::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-image: linear-gradient(90deg, var(--thm-black) 0%, rgba(var(--thm-black-rgb), 0) 100%); */
	background-image: linear-gradient(to bottom right, var(--thm-black) 0%, rgba(var(--thm-black-rgb), 0) 10%);
}

@media (min-width: 768px) {
	.slider-one__image {
		background-position: center right -185px;
	}
}

@media (min-width: 1700px) {
	.slider-one__image {
		background-position: center right;
	}
}

@media (max-width: 600px) {
	.slider-one__image {
		background-position: center right -145px;
	}

	.slider-one__image2 {
		background-position: center right -25px;
	}
}

.active .slider-one__image {
	transform: scale(1.05);
	transition: all 7000ms linear;
}

.slider-one .container {
	position: relative;
	z-index: 12;
}

.slider-one__tagline {
	margin: 0;
	text-transform: uppercase;
	letter-spacing: .2em;
	color: #ffffff;
	font-size: 16px;
	margin-bottom: 25px;
	opacity: 0;
	transform: translateY(80px);
}

@media (min-width: 992px) {
	.slider-one__tagline {
		margin-bottom: 23px;
		font-size: 18px;
	}
}

.slider-one__tagline>i {
	position: relative;
	top: 5px;
	font-size: 20px;
	color: var(--thm-base);
}

@media (min-width: 992px) {
	.slider-one__tagline>i {
		font-size: 30px;
		margin-right: 8px;
	}
}

.active .slider-one__tagline {
	opacity: 1;
	transform: translate(0);
	transition: 300ms ease;
	transition-delay: 800ms;
}

.slider-one__title {
	margin: 0;
	color: #ffffff;
	font-weight: 800;
	font-size: 39px;
	line-height: 1.2em;
	margin-bottom: 30px;
	opacity: 0;
	transform: translateY(80px);
}



@media (min-width: 992px) {
	.slider-one__title {
		font-size: 45px;
		margin-bottom: 24px;
	}
}

@media (min-width: 1200px) {
	.slider-one__title {
		font-size: 55px;
		max-width: 690px;
	}
}

.slider-one__title span {
	position: relative;
}

.slider-one__title span::before {
	content: '';
	background-image: url(../images/shapes/main-slider-t-1-1.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
	position: absolute;
	top: 5px;
	left: -4px;
	width: 100%;
	height: 100%;
	z-index: -1;
	animation: zoom-fade 5s linear 0s infinite;
}

.active .slider-one__title {
	opacity: 1;
	transform: translate(0);
	transition: 200ms ease;
	transition-delay: 1300ms;
}

.slider-one__text {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 30px;
	opacity: 0;
	transform: translateY(100px);
}

@media (min-width: 992px) {
	.slider-one__text {
		font-size: 18px;
		margin-bottom: 40px;
	}
}

.active .slider-one__text {
	opacity: 1;
	transform: translateY(0);
	transition: 200ms ease;
	transition-delay: 1400ms;
}

.slider-one__btns {
	opacity: 0;
	transform: translateY(50px);
}

.active .slider-one__btns {
	opacity: 1;
	transform: translateY(0);
	transition: 200ms ease;
	transition-delay: 1800ms;
}

.slider-one__carousel__btn {
	display: none;
	position: absolute;
	top: 50%;
	left: 60px;
	transform: translateY(-50%);
	z-index: 15;
}

@media (min-width: 992px) {
	.slider-one__carousel__btn {
		display: flex;
		flex-direction: column;
	}
}

.slider-one__carousel__btn button {
	border: none;
	outline: none;
	width: 51px;
	height: 51px;
	font-size: 20px;
	color: var(--thm-black);
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.3;
	transition: all 0.5s ease;
}

.slider-one__carousel__btn button:hover {
	opacity: 1;
}

.slider-one__carousel__btn button+button {
	margin-top: 10px;
}

.slider-one__box {
	display: none;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 20;
}

@media (min-width: 992px) {
	.slider-one__box {
		display: flex;
	}
}

.slider-one__box__icon {
	width: 109px;
	height: 109px;
	background-color: var(--thm-base);
	color: #fff;
	font-size: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-one__box__content {
	background-color: #fff;
	margin-left: 10px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 35px;
}

.slider-one__box__tagline {
	font-size: 12px;
	text-transform: uppercase;
	color: var(--thm-base);
	letter-spacing: .2em;
	font-weight: 600;
	margin: 0;
	line-height: 1;
	margin-bottom: 10px;
}

.slider-one__box__title {
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	color: var(--thm-black);
	line-height: 1;
}

.slider-one--two .slider-one__item {
	background-color: var(--thm-gray);
	padding-top: 160px;
}

@media (min-width: 992px) {
	.slider-one--two .slider-one__item {
		min-height: 725px;
		padding-top: 25px;
	}
}

.slider-one--two .slider-one__tagline {
	background-color: #fff;
	color: var(--thm-base);
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 7px;
	padding-bottom: 7px;
}

.slider-one--two .slider-one__title {
	color: var(--thm-black);
}

@media (min-width: 1200px) {
	.slider-one--two .slider-one__title {
		max-width: 453px;
	}
}

.slider-one--two .slider-one__title span::before {
	width: 51px;
	height: 48px;
	background-image: url(../images/shapes/slider-2-s-1.png);
	background-repeat: no-repeat;
	background-size: contain;
	left: auto;
	animation: none;
	position: absolute;
	top: -10px;
	right: -35px;
}

.slider-one--two .slider-one__text {
	color: var(--thm-color);
}

.slider-one--two .slider-one__carousel__btn button {
	opacity: 0.5;
}

.slider-one--two .slider-one__carousel__btn button:hover {
	opacity: 1;
}

@media (min-width: 992px) {
	.slider-one--three .slider-one__item {
		min-height: 717px;
	}
}

.slider-one--three .slider-one__carousel__btn {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 1450px;
	padding-left: 15px;
	padding-right: 15px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	display: none;
}

@media (min-width: 768px) {
	.slider-one--three .slider-one__carousel__btn {
		display: flex;
	}
}

.slider-one--three .slider-one__carousel__btn button+button {
	margin-top: 0;
}

.slider-one--three .slider-one__title {
	font-weight: bold;
}

.slider-one--three .slider-one__title br {
	display: none;
}

@media (min-width: 1200px) {
	.slider-one--three .slider-one__title {
		font-size: 72px;
		max-width: 100%;
	}

	.slider-one--three .slider-one__title br {
		display: inherit;
	}
}

@media (min-width: 992px) {
	.slider-one--three .slider-one__text {
		margin-bottom: 30px;
	}
}

/*--------------------------------------------------------------
# Case
--------------------------------------------------------------*/
.case-card-one {
	position: relative;
	background-color: var(--thm-black);
	min-height: 380px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding: 30px;
}

.case-card-one__link {
	width: 47px;
	height: 47px;
	background-color: var(--thm-base);
	color: #fff;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 10;
	border-radius: 50%;
	transform: translateY(-20%);
	opacity: 0;
	visibility: hidden;
	transition: 0.5s ease;
}

.case-card-one:hover .case-card-one__link {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
}

.case-card-one__image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-position: center;
}

.case-card-one__image::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 86%;
	background-image: linear-gradient(0deg, var(--thm-black) 0%, rgba(var(--thm-black-rgb), 0) 50%);
}

.case-card-one__content {
	position: relative;
	z-index: 10;
}

.case-card-one__title {
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 8px;
}

.case-card-one__title a {
	color: inherit;
	transition: all 0.5s ease;
}

.case-card-one__title a:hover {
	color: var(--thm-base);
}

.case-card-one__text {
	margin: 0;
	color: var(--thm-base);
	text-transform: uppercase;
	letter-spacing: .2em;
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
}

.case-one .container-fluid {
	max-width: 1594px;
}

.case-one__carousel .owl-carousel .owl-nav {
	display: none;
}

.case-one__carousel .owl-carousel .owl-dots {
	margin-top: 45px;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
	width: 25px;
	height: 25px;
	border-radius: 100%;
	border: 1px solid var(--thm-base);
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	position: fixed;
	pointer-events: none;
	left: 0;
	top: 0;
	-webkit-transform: translate(calc(-50% + 5px), -50%);
	transform: translate(calc(-50% + 5px), -50%);
	z-index: 999991;
}

.custom-cursor__cursor-two {
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: var(--thm-base);
	opacity: .3;
	position: fixed;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	pointer-events: none;
	-webkit-transition: width .3s, height .3s, opacity .3s;
	transition: width .3s, height .3s, opacity .3s;
	z-index: 999991;
}

.custom-cursor__hover {
	background-color: var(--thm-base);
	opacity: 0.4;
}

.custom-cursor__innerhover {
	width: 25px;
	height: 25px;
	opacity: .4;
}

/*--------------------------------------------------------------
# Compare
--------------------------------------------------------------*/
.loan-comparison__body .form-input {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
}

.loan-comparison__body .form-input label {
	display: block;
	font-size: 20px;
	font-weight: 500;
	color: var(--thm-black);
}

.loan-comparison__body .form-input input {
	border-radius: 0;
	border: 1px solid var(--thm-border-color);
	width: 100%;
	padding: 5px 15px;
	outline: 0;
}

.compare-table .table {
	border: 1px solid var(--thm-border-color);
	min-width: 700px;
}

.compare-table .table th {
	border: 1px solid var(--thm-border-color);
	background-color: var(--thm-base);
	padding-top: 10px;
	padding-bottom: 10px;
	color: #ffffff;
	vertical-align: middle;
	font-size: 18px;
	font-weight: 500;
}

.compare-table .table tbody {
	border-top: 0;
}

.compare-table .table td {
	border: 1px solid var(--thm-border-color);

}

.compare-table .table td a {
	color: var(--thm-base);
}

.compare-table .table td:nth-child(1) {
	width: 20%;
}

.compare-table .table td:nth-child(2),
.compare-table .table td:nth-child(3) {
	width: 25%;
}

.compare-table .table td:nth-child(4) {
	width: 30%;
}

.compare-table .table tr.lowest_emi {
	background-color: var(--thm-primary);
}

.lowest-emi-note {
	display: flex;
	align-items: center;
}

.indicator {
	margin-right: 10px;
	display: inline-block;
	border: 1px solid var(--thm-border-color);
	width: 20px;
	height: 20px;
	background-color: var(--thm-primary);
}

/*--------------------------------------------------------------
# Home loan Amortization calculator
--------------------------------------------------------------*/

.amortization-schedule {
	margin-bottom: 60px;
	overflow-x: auto;
}

.amortization-schedule .amortization-inner {
	border: 1px solid var(--thm-border-color);
	min-width: 560px;
}

.amortization-schedule .amortization-title {
	display: flex;
	border-bottom: 1px solid var(--thm-border-color);
}

.amortization-schedule .amortization-title-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: 10px;
	flex: 1 1 0;
	color: #fff;
}

.amortization-schedule .amortization-data {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 10px;
	flex: 1 1 0;
	color: var(--thm-black);
}

.amortization-schedule .amortization-data.year-outer {
	cursor: pointer;
}

.amortization-schedule .amortization-title {
	background-color: var(--thm-base);
	color: #fff;
	font-weight: 600;
	font-size: 18px;
}

.amortization-schedule .amortization-title-inner:not(:last-child) {
	border-right: 1px solid var(--thm-border-color);
}

.amortization-schedule .amortization-content .amortization-column {
	display: flex;
}

.amortization-content .amortization-column:nth-child(2n+1) {
	background-color: var(--thm-gray);
}

.amortization-content .amortization-column.sum {
	background-color: var(--thm-black);
	border-bottom: 2px solid var(--thm-border-color);
}

.amortization-content .amortization-column.sum i {
	margin-right: 10px;
}

.amortization-schedule .amortization-data:not(:last-child) {
	border-right: 1px solid var(--thm-border-color);
}

.amortization-column.sum .amortization-data {
	color: #fff;
}

@media(max-width: 768px) {
	/*.amortization-schedule .amortization-title-inner.total-payment-title,
	.amortization-column.sum .amortization-data.total-payment-sum,
	.amortization-schedule .amortization-data.total-payment{
		display: none;
	}*/
}

@media(max-width: 768px) {
	.amortization-schedule .amortization-data {
		font-size: 14px;
		padding-top: 10px;
	}

	.amortization-schedule .amortization-title-inner {
		font-size: 16px;
	}
}

@media(max-width: 576px) {}

.loan-calculation-chart {
	position: relative;
	z-index: 10;
	margin-left: 0px;
	background-color: #fff;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 30px 0px 10px 0;
	margin-top: 40px;
}

.loan-start-date {
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0px 15px 0px 15px;
	align-items: center;
}

.loan-start-date label {
	white-space: nowrap;
	margin-right: 40px;
}

.loan-start-date label h4 {
	font-size: 22px;
	margin-bottom: 0;
}

.loan-start-date input {
	display: block;
	width: 100%;
	background-color: var(--thm-gray);
	color: var(--thm-black);
	font-size: 14px;
	font-weight: 400;
	border: none;
	outline: none;
	padding: 7px 10px;
}

.loan-start-date i {
	position: absolute;
	right: 30px;
	z-index: 0;
	pointer-events: none;
}

.datepicker table tr td span.active {
	background-color: var(--thm-base) !important;
	border-radius: 0;
}

/*--------------------------------------------------------------
# Home loan eligibility calculator
--------------------------------------------------------------*/

.eligibility-button {
	/*width: 100%;*/
	text-align: center;
}

.eligibility-button .thm-btn:hover {
	background-color: var(--thm-black);
	color: #fff;
}

.home-eligibility-loan {
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 30px;
	padding-top: 50px;

}

.home-eligibility-loan .home-eligibility-inner {
	text-align: left;
	font-size: 30px;
	color: var(--thm-black);
}

.home-eligibility-loan .home-eligibility-inner .thm-btn {
	text-align: center;
}

.loan-calculation.home-eligibility {

	background-color: var(--thm-gray);
}

.home-eligibility-inner p+p {
	padding-top: 25px;
	margin-top: 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.home-eligibility-loan .home-eligibility-inner h4 {
	font-weight: 700;
}

/*--------------------------------------------------------------
# Apply-now
--------------------------------------------------------------*/

.finloan-apply-one {
	padding-top: 120px;
	padding-bottom: 120px;
	background-color: var(--thm-gray);
}

@media (max-width: 991px) {
	.finloan-apply-one {
		padding-top: 40px;
		padding-bottom: 80px;
	}
}

@media (max-width: 767px) {
	.finloan-apply-one {
		padding-top: 25px;
		padding-bottom: 60px;
	}
}

@media (max-width: 425px) {
	.finloan-apply-one {
		padding-top: 10px;
		padding-bottom: 50px;
	}
}

.finloan-apply-one .block-title {
	margin-bottom: 20px;
}

.contact-one__form-box .form-group {
	position: relative;
	margin-bottom: 20px;
}

.contact-one__form-box .form-group label {
	margin-bottom: 7px;
	color: var(--thm-black);
}


.contact-one__form-box .form-group select {
	appearance: none;
}

.contact-one__form-box .form-group select+i {
	position: absolute;
	right: 30px;
	bottom: 22px;
	pointer-events: none;
	font-size: 12px;
}

.slider {
	border-radius: 5px;
	overflow: hidden;
	height: 7px;
	width: 100%;
	-webkit-appearance: none;
	background-color: #e8e2e2;
	cursor: pointer;
}


.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-runnable-track {
	height: 100px;
	-webkit-appearance: none;
	color: #000;
	margin-top: -1px;
}

.slider::-webkit-slider-thumb {
	width: 20px;
	-webkit-appearance: none;
	height: 20px;

	background: #000;
	border-radius: 50%;
	box-shadow: -500px 0 0 500px #fd7e14;
}


.container2 {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container2:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked~.checkmark {
	background-color: #7D004D;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container2 input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark:after {
	left: 9px;
	top: 7px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.container3 {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.container3 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom radio button */
.container3 .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container3:hover input~.checkmark {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container3 input:checked~.checkmark {
	background-color: #7D004D;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container3 .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container3 input:checked~.checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container3 .checkmark:after {
	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}